import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model({ position, ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("./Models/cinturonNQRQLR.gltf");
  return (
    <group ref={group} {...props} dispose={null} position={position}>
      <mesh geometry={nodes.Plastico.geometry}>
        <meshStandardMaterial
          color={0x000000}
          envMapIntensity={1.2}
          metalness={0.5}
          roughness={0.3}
        />
      </mesh>
      <mesh geometry={nodes.Correa.geometry}>
        <meshStandardMaterial
          color={0x383838}
          envMapIntensity={1.2}
          metalness={0.5}
          roughness={1}
        />
      </mesh>
      <mesh geometry={nodes.Metal.geometry}>
        <meshStandardMaterial
          color={0xbababa}
          envMapIntensity={1.2}
          metalness={0.8}
          roughness={0.1}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload("./Models/cinturonNQRQLR.gltf");
