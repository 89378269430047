import { Button } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Loading = () => {
  const global = useContext(AppContext);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      {global.showIdioma && (
        <div className="loading" style={{ backgroundColor: "white" }}>
          <img className="Logo" src="./Media/logo.svg" alt="Logo" />
          {/*<CustomizedDialogs />
     Si "showIdioma" es true muestra los botones para seleccionar el idioma*/}

          <div className="loading">
            <Button
              variant="contained"
              className="botton1"
              onClick={() => {
                //cambiamos el estado de la variable global "showIdioma" a false, par aocultar al hacer click
                global.establecerIdioma("es");
                handleClickOpen();
                global.setShowIdioma(false);
              }}
            >
              Español
            </Button>
            <Button
              variant="contained"
              className="botton2"
              onClick={() => {
                //cambiamos el estado de la variable global "showIdioma" a false, par aocultar al hacer click
                global.establecerIdioma("en");
                handleClickOpen();
                global.setShowIdioma(false);
              }}
            >
              English
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Loading;
