import * as THREE from "three";
import React, { useRef, useState, useEffect } from "react";
import { useLoader, useFrame, useThree } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { useGLTF } from "@react-three/drei";
import { useSpring, animated } from "@react-spring/three";
import { Select } from "@react-three/postprocessing";
import Placas from "../components/placas";

export default function NPR({ ...props }) {
  const group = useRef();
  const { nodes } = useGLTF("./Models/FVR.gltf");
  const envMapIntensity = 1.4;

  var position = null;
  if (props.vista === "Interior") {
    position = [-1.7, -1.4, 0];
  } else if (props.vista === "Exterior") {
    position = [0, -1.05, 0];
  }

  const myMesh = React.useRef();
  const myMeshL = React.useRef();
  const [active, setActive] = useState(false);
  const [activeL, setActiveL] = useState(false);

  const { rotation } = useSpring({
    rotation: active ? [0, 1.047, 0] : [0, 0, 0],
  });
  const { rotationL } = useSpring({
    rotationL: activeL ? [0, -1.047, 0] : [0, 0, 0],
  });

  const [hovered, set] = useState(false);
  const [hoveredL, setL] = useState(false);
  useFrame(({ clock }) => {});

  const dao01 = useLoader(TextureLoader, "./Textures/traviz/dao01.jpg");
  dao01.flipY = false;

  const rao01 = useLoader(TextureLoader, "./Textures/traviz/rao01.jpg");
  rao01.flipY = false;

  const dao01FVR = useLoader(TextureLoader, "./Textures/FVR/dao01.jpg");
  dao01FVR.flipY = false;

  const rao01FVR = useLoader(TextureLoader, "./Textures/FVR/rao01.jpg");
  rao01FVR.flipY = false;

  const dao02FVR = useLoader(TextureLoader, "./Textures/FVR/dao02.jpg");
  dao02FVR.flipY = false;

  const rao02FVR = useLoader(TextureLoader, "./Textures/FVR/rao02.jpg");
  rao02FVR.flipY = false;

  const n01 = useLoader(TextureLoader, "./Textures/traviz/n01.png");
  n01.flipY = false;

  const FarosNormal = useLoader(TextureLoader, "./Textures/FarosNormal.png");

  const FarosRelieveAnchoNormal = useLoader(
    TextureLoader,
    "./Textures/FarosRelieveAnchoNormal.png"
  );
  FarosRelieveAnchoNormal.wrapS = THREE.RepeatWrapping;
  FarosRelieveAnchoNormal.wrapT = THREE.RepeatWrapping;
  FarosRelieveAnchoNormal.repeat.set(3, 3);

  const FarosRelieveFinoNormal = useLoader(
    TextureLoader,
    "./Textures/FarosRelieveFinoNormal.png"
  );
  FarosRelieveFinoNormal.wrapS = THREE.RepeatWrapping;
  FarosRelieveFinoNormal.wrapT = THREE.RepeatWrapping;
  FarosRelieveFinoNormal.repeat.set(8, 8);

  var PinturaBlanca = new THREE.MeshPhysicalMaterial({
    metalness: 0.2,
    clearcoat: 1,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });

  var PinturaNegraMetal = new THREE.MeshStandardMaterial({
    roughness: 0.5,
    envMapIntensity: 1,
    metalness: 0.5,
    side: THREE.DoubleSide,
    color: 0x222222,
  });
  var Chromado = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    roughness: 0.2,
    //lightMap: ao02,
    envMapIntensity: envMapIntensity,
    metalness: 1,
  });
  var PlasticoNegro1 = new THREE.MeshStandardMaterial({
    color: 0x737373,
    roughness: 0.8,
    map: dao01FVR,
    roughnessMap: rao01FVR,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var PlasticoNegro = new THREE.MeshStandardMaterial({
    color: 0x000000,
    roughness: 0.5,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var PlasticoGrisOscuro = new THREE.MeshStandardMaterial({
    color: 0xb8b8b8,
    roughness: 0.7,
    map: dao01FVR,
    roughnessMap: rao01FVR,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var PlasticoInteriorBrillante = new THREE.MeshStandardMaterial({
    color: 0xb8b8b8,
    roughness: 0.7,
    map: dao02FVR,
    roughnessMap: rao02FVR,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var LogoChromado = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    roughness: 0.1,
    envMapIntensity: envMapIntensity,
    metalness: 1,
  });
  var VidriosOscuros = new THREE.MeshPhysicalMaterial({
    color: 0xa1a1a1, //0xa1a1a1,
    transmission: 1,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0,
    metalness: 0.1,
    thickness: 0.01,
    specularIntensity: 1,
    specularColor: 0x000000,
    side: THREE.DoubleSide,
  });
  var GomaNegra = new THREE.MeshStandardMaterial({
    color: 0x000000,
    envMapIntensity: envMapIntensity,
    roughness: 0.5,
    metalness: 1,
  });
  var Escape = new THREE.MeshStandardMaterial({
    roughness: 0.5,
    envMapIntensity: 0.3,
    metalness: 1,
    side: THREE.DoubleSide,
  });
  var MetalGris = new THREE.MeshPhysicalMaterial({
    map: dao02FVR,
    roughnessMap: rao02FVR,
    metalness: 0.8,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var MetalGris2 = new THREE.MeshPhysicalMaterial({
    color: 0xb3b3b3,
    metalness: 0.8,
    roughness: 0.4,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var Cauchos = new THREE.MeshStandardMaterial({
    map: dao01,
    roughnessMap: rao01,
    envMapIntensity: envMapIntensity,
    roughness: 0.65,
    color: 0x000000,
    normalMap: n01,
    side: THREE.DoubleSide,
  });
  var Baterias = new THREE.MeshStandardMaterial({
    envMapIntensity: envMapIntensity,
  });
  var Espejo = new THREE.MeshPhysicalMaterial({
    color: 0xffffff,
    metalness: 0.85,
    roughness: 0.3,
    envMapIntensity: 1,
  });
  var PlasticoMicasFrontal = new THREE.MeshPhysicalMaterial({
    color: 0xffffff,
    transmission: 1,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0,
    metalness: 0,
    thickness: 0.1,
    side: THREE.DoubleSide,
  });
  var PlasticoMicasFrontalRelieve = new THREE.MeshPhysicalMaterial({
    color: 0xffffff,
    transmission: 0.7,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.05,
    metalness: 0.4,
    thickness: 0.04,
    specularIntensity: 1,
    specularColor: 0xffffff,
    normalMap: FarosRelieveAnchoNormal,
    side: THREE.DoubleSide,
  });
  var PlasticoBlanco = new THREE.MeshPhysicalMaterial({
    color: 0x7d7d7d,
    transmission: 0.1,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    thickness: 0,
    normalMap: FarosNormal,
  });
  var PlasticoAmarilloM = new THREE.MeshStandardMaterial({
    color: 0xd18f00,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    thickness: 0,
    normalMap: FarosNormal,
    metalness: 1,
  });
  var PlasticoAmarillo = new THREE.MeshPhysicalMaterial({
    color: 0x854700,
    transmission: 0.1,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    thickness: 0,
    normalMap: FarosNormal,
  });
  var PlasticoRojo = new THREE.MeshPhysicalMaterial({
    color: 0x850000,
    transmission: 0.1,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    thickness: 0,
    normalMap: FarosNormal,
  });
  var AlfombraGris = new THREE.MeshStandardMaterial({
    color: 0xb8b8b8,
    roughness: 0.6,
    envMapIntensity: envMapIntensity,
    map: dao01FVR,
    roughnessMap: rao01FVR,
    side: THREE.DoubleSide,
  });
  var Techo = new THREE.MeshStandardMaterial({
    color: 0xb8b8b8,
    roughness: 0.7,
    envMapIntensity: envMapIntensity,
    map: dao02FVR,
    roughnessMap: rao02FVR,
    side: THREE.DoubleSide,
  });
  var PlasticoGris1 = new THREE.MeshStandardMaterial({
    color: 0xb8b8b8,
    roughness: 0.7,
    map: dao02FVR,
    roughnessMap: rao02FVR,
    envMapIntensity: envMapIntensity,
  });
  var Cuero2 = new THREE.MeshStandardMaterial({
    color: 0xb8b8b8,
    roughness: 0.8,
    map: dao02FVR,
    roughnessMap: rao02FVR,
    envMapIntensity: envMapIntensity,
  });
  var Fondo = new THREE.MeshStandardMaterial({
    color: 0x2e2e2e,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });

  var Material = new THREE.MeshStandardMaterial();

  return (
    <>
      <group ref={group} {...props} dispose={null} position={position}>
        <mesh
          geometry={nodes.CauchoRepuesto_FVR.geometry}
          material={Cauchos}
        ></mesh>
        <mesh geometry={nodes.Cauchos_FVR.geometry} material={Cauchos}></mesh>
        <mesh geometry={nodes.Baterias_FVR.geometry} material={Baterias}></mesh>
        <mesh geometry={nodes.Chromado_FVR.geometry} material={Chromado}></mesh>
        <mesh
          geometry={nodes.PlasticoGrisOscuro_FVR.geometry}
          material={PlasticoGrisOscuro}
        ></mesh>
        <mesh
          geometry={nodes.Alfombra_FVR.geometry}
          material={AlfombraGris}
        ></mesh>
        <mesh geometry={nodes.Cuero1_FVR.geometry} material={Cuero2}></mesh>
        <mesh geometry={nodes.Cuero2_FVR.geometry} material={Cuero2}></mesh>
        <mesh geometry={nodes.Cuero3_FVR.geometry} material={Cuero2}></mesh>
        <mesh geometry={nodes.Cuero4_FVR.geometry} material={Cuero2}></mesh>
        <mesh geometry={nodes.Escape_FVR.geometry} material={Escape}></mesh>
        <mesh geometry={nodes.Espejo2_FVR.geometry} material={Espejo}></mesh>
        <mesh geometry={nodes.Espejo_FVR.geometry} material={Espejo}></mesh>
        <mesh geometry={nodes.Fondo1_FVR.geometry} material={Fondo}></mesh>
        <mesh geometry={nodes.Goma_FVR.geometry} material={GomaNegra}></mesh>
        <mesh
          geometry={nodes.GomaNegra2_FVR.geometry}
          material={GomaNegra}
        ></mesh>
        <mesh geometry={nodes.Interior_FVR.geometry} material={Techo}></mesh>
        <mesh geometry={nodes.Logo_FVR.geometry} material={LogoChromado}></mesh>
        <mesh
          geometry={nodes.MetalBlanco_FVR.geometry}
          material={PinturaBlanca}
        ></mesh>
        <mesh
          geometry={nodes.MetalGris_FVR.geometry}
          material={MetalGris}
        ></mesh>
        <mesh
          geometry={nodes.MetalNegro_FVR.geometry}
          material={PinturaNegraMetal}
        ></mesh>
        <mesh
          geometry={nodes.PinturaBlancaRim_FVR.geometry}
          material={MetalGris2}
        ></mesh>
        <mesh
          geometry={nodes.PinturaBlancaRimRepuesto_FVR.geometry}
          material={MetalGris2}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoAmarillo_FVR.geometry}
          material={PlasticoAmarillo}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoAmarilloM_FVR.geometry}
          material={PlasticoAmarilloM}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoBlanco_FVR.geometry}
          material={PlasticoBlanco}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoMicasFrontal_FVR.geometry}
          material={PlasticoMicasFrontalRelieve}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoBlanco_FVR.geometry}
          material={PlasticoBlanco}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoMicasFrontalRelieve_FVR.geometry}
          material={PlasticoMicasFrontal}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoNegro1_FVR.geometry}
          material={PlasticoNegro1}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoNegro_FVR.geometry}
          material={PlasticoNegro}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoRojo_FVR.geometry}
          material={PlasticoRojo}
        ></mesh>
        <mesh
          geometry={nodes.PlastinoNegroBrillante_FVR.geometry}
          material={PlasticoInteriorBrillante}
        ></mesh>
        <mesh
          geometry={nodes.Vidrio_FVR.geometry}
          material={VidriosOscuros}
        ></mesh>

        {/*------------CINTURON------------*/}
        <mesh geometry={nodes.Correa_FVR.geometry}>
          <meshStandardMaterial
            color={0x383838}
            envMapIntensity={1.2}
            metalness={0.5}
            roughness={1}
          />
        </mesh>
        <mesh geometry={nodes.Metal_FVR.geometry}>
          <meshStandardMaterial
            color={0xbababa}
            envMapIntensity={1.2}
            metalness={0.8}
            roughness={0.1}
          />
        </mesh>
        <mesh geometry={nodes.Plastico_FVR.geometry}>
          <meshStandardMaterial
            color={0x000000}
            envMapIntensity={1.2}
            metalness={0.5}
            roughness={0.3}
          />
        </mesh>

        {/*----------------------------------*
         *------------ANIMACIONES-----------*
         *----------------------------------*/}

        <Select name="puertaR" enabled={hovered}>
          <animated.mesh
            position={[3.743, 1.38, 0.912]}
            rotation={rotation}
            onClick={() => setActive(!active)}
            ref={myMesh}
            scale={[1, 1, 1]}
            onPointerOver={() => {
              set(true);
            }}
            onPointerOut={() => {
              set(false);
            }}
          >
            <mesh
              geometry={nodes.PuertaChromadoR_FVR.geometry}
              material={Chromado}
            ></mesh>
            <mesh
              geometry={nodes.PuertaEspejoR_FVR.geometry}
              material={Espejo}
            ></mesh>
            <mesh
              geometry={nodes.PuertaGomaR_FVR.geometry}
              material={GomaNegra}
            ></mesh>
            <mesh
              geometry={nodes.PuertaMetalBlancoR_FVR.geometry}
              material={PinturaBlanca}
            ></mesh>
            <mesh
              geometry={nodes.PuertaMetalNegroR_FVR.geometry}
              material={PinturaNegraMetal}
            ></mesh>
            <mesh
              geometry={nodes.PuertaPlasticoGrisR_FVR.geometry}
              material={PlasticoGris1}
            ></mesh>
            <mesh
              geometry={nodes.PuertaPlasticoNegroR_FVR.geometry}
              material={PlasticoNegro}
            ></mesh>
            <mesh
              geometry={nodes.PuertaPlasticoRelieveR_FVR.geometry}
              material={PlasticoMicasFrontalRelieve}
            ></mesh>
            <mesh
              geometry={nodes.PuertaPlasticoRojoR_FVR.geometry}
              material={PlasticoRojo}
            ></mesh>
            <mesh
              geometry={nodes.PuertaVidrioR_FVR.geometry}
              material={VidriosOscuros}
            ></mesh>
          </animated.mesh>
        </Select>

        <Select name="puertaL" enabled={hoveredL}>
          <animated.mesh
            position={[3.743, 1.38, -0.912]}
            rotation={rotationL}
            onClick={() => setActiveL(!activeL)}
            ref={myMeshL}
            onPointerOver={() => {
              setL(true);
            }}
            onPointerOut={() => {
              setL(false);
            }}
          >
            <mesh
              geometry={nodes.PuertaChromadoL_FVR.geometry}
              material={Chromado}
            ></mesh>
            <mesh
              geometry={nodes.PuertaEspejoL_FVR.geometry}
              material={Espejo}
            ></mesh>
            <mesh
              geometry={nodes.PuertaGomaL_FVR.geometry}
              material={GomaNegra}
            ></mesh>
            <mesh
              geometry={nodes.PuertaMetalBlancoL_FVR.geometry}
              material={PinturaBlanca}
            ></mesh>
            <mesh
              geometry={nodes.PuertaMetalNegroL_FVR.geometry}
              material={PinturaNegraMetal}
            ></mesh>
            <mesh
              geometry={nodes.PuertaPlasticoGrisL_FVR.geometry}
              material={PlasticoGris1}
            ></mesh>
            <mesh
              geometry={nodes.PuertaPlasticoNegroL_FVR.geometry}
              material={PlasticoNegro}
            ></mesh>
            <mesh
              geometry={nodes.PuertaPlasticoRelieveL_FVR.geometry}
              material={PlasticoMicasFrontalRelieve}
            ></mesh>
            <mesh
              geometry={nodes.PuertaPlasticoRojoL_FVR.geometry}
              material={PlasticoRojo}
            ></mesh>
            <mesh
              geometry={nodes.PuertaVidrioL_FVR.geometry}
              material={VidriosOscuros}
            ></mesh>
          </animated.mesh>
        </Select>
      </group>
      <Placas position={[3.98, -0.32, 0]} camion={"FVR"} />
    </>
  );
}

useGLTF.preload("./Models/FVR.gltf");
