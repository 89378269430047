/* eslint-disable import/no-anonymous-default-export */
export default [
  {
    src: "./Media/pseries.svg",
    src2: "./Media/p-series.svg",
  },
  {
    src: "./Media/qseries.svg",
    src2: "./Media/q-series.svg",
  },
  {
    src: "./Media/nseries.svg",
    src2: "./Media/n-series.svg",
  },
  {
    src: "./Media/fseries.svg",
    src2: "./Media/f-series.svg",
  },
];
