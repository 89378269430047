import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { useLoader } from "@react-three/fiber";

export default function Model({ position, camion, ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("./Models/placa.gltf");

  const fserie = useLoader(TextureLoader, "./Textures/Placas/f-series.png");
  fserie.flipY = false;

  const pserie = useLoader(TextureLoader, "./Textures/Placas/p-series.png");
  pserie.flipY = false;

  const qserie = useLoader(TextureLoader, "./Textures/Placas/q-series.png");
  qserie.flipY = false;

  const nserie = useLoader(TextureLoader, "./Textures/Placas/n-series.png");
  nserie.flipY = false;

  var material = null;
  if (camion === "NQR") {
    material = nserie;
  } else if (camion === "FVR") {
    material = fserie;
  } else if (camion === "QLR") {
    material = qserie;
  } else if (camion === "Traviz") {
    material = pserie;
  } else if (camion === "NPR") {
    material = nserie;
  } else if (camion === "NMR") {
    material = nserie;
  }

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      position={position}
      scale={1.2}
    >
      <mesh geometry={nodes.placa.geometry}>
        <meshStandardMaterial
          color={0xffffff}
          map={material}
          envMapIntensity={1.2}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload("./Models/placa.gltf");
