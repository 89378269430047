import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Visibility, VrpanoSharp } from "@mui/icons-material";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { FormattedMessage } from "react-intl";
import Popover from "@mui/material/Popover";
import { Divider } from "@mui/material";
import Menu from "@mui/material/Menu";
import { useNavigate } from "react-router-dom";

const NavBar = ({ camion, ...props }) => {
  const global = useContext(AppContext);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);

  const handleClick = (event, menu) => {
    setAnchorEl(event.currentTarget);
    setSelectedMenu(menu);
  };

  const handleClose = () => {
    console.log("handleClose called"); // Agrega esta línea
    setAnchorEl(null);
    setSelectedMenu(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  const menuItems = {
    vistas: [
      {
        id: "navbar.Interior",
        onClick: () => global.setActiveVista("Interior"),
      },
      {
        id: "navbar.Exterior",
        onClick: () => global.setActiveVista("Exterior"),
      },
    ],
    fondos: [
      {
        id: "navbar.Pabellon",
        onClick: () => {
          global.setActiveFondo("./Textures/blanco.hdr");
        },
      },
      {
        id: "navbar.Paisaje",
        onClick: () => {
          global.setActiveFondo("./Textures/ambiente.hdr");
        },
      },
    ],
    traviz: [
      { id: "Traviz Cab-Chassis", to: "/Traviz" },
      { id: "Traviz Cargo/Flatbed", to: "/Traviz-flat-Bed" },
    ],
    serieQ: [
      { id: "QLR 77L", to: "/QLR77L" },
      { id: "QMR 77L", to: "/QMR77L" },
    ],
    serieN: [
      { id: "NMR85", to: "/NMR" },
      { id: "NPR 71L", to: "/NPR" },
      { id: "NQR 90L", to: "/NQR90L" },
    ],
    serieF: [{ id: "FVR 34", to: "/FVR" }],
  };

  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <>
          <ListSubheader component="div" id="nested-list-subheader">
            <img className="logo-navbar" src="./Media/logo.svg" alt="Logo" />
          </ListSubheader>
        </>
      }
    >
      <ListItemButton onClick={(event) => handleClick(event, "vistas")}>
        <ListItemIcon className="listresponsive">
          <Visibility />
        </ListItemIcon>
        <ListItemText primary={<FormattedMessage id="navbar.Vistas" />} />
      </ListItemButton>

      <Divider />

      <ListItemButton onClick={(event) => handleClick(event, "fondos")}>
        <ListItemIcon className="listresponsive">
          <VrpanoSharp />
        </ListItemIcon>
        <ListItemText primary={<FormattedMessage id="navbar.Fondos" />} />
      </ListItemButton>

      <Divider />

      <ListItemButton onClick={(event) => handleClick(event, "traviz")}>
        <ListItemText primary={<FormattedMessage id="navbar.Traviz" />} />
      </ListItemButton>

      <Divider />

      <ListItemButton onClick={(event) => handleClick(event, "serieQ")}>
        <ListItemText primary={<FormattedMessage id="navbar.Q" />} />
      </ListItemButton>

      <Divider />

      <ListItemButton onClick={(event) => handleClick(event, "serieN")}>
        <ListItemText primary={<FormattedMessage id="navbar.N" />} />
      </ListItemButton>

      <Divider />

      <ListItemButton onClick={(event) => handleClick(event, "serieF")}>
        <ListItemText primary={<FormattedMessage id="navbar.F" />} />
      </ListItemButton>

      <Divider />

      <Menu
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <List component="div" disablePadding>
          {selectedMenu &&
            menuItems[selectedMenu].map((item, index) => (
              <React.Fragment key={index}>
                {item.to ? (
                  <NavLink
                    to={item.to}
                    style={{ textDecoration: "none" }}
                    onClick={(e) => {
                      global.setActiveVista("Exterior");
                      e.preventDefault();
                      handleClose();
                      setTimeout(() => {
                        navigate(item.to); // Cambia esta línea
                      }, 100);
                    }}
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText primary={item.id} />
                    </ListItemButton>
                  </NavLink>
                ) : (
                  <ListItemButton
                    onClick={() => {
                      handleClose();
                      setTimeout(() => {
                        item.onClick();
                      }, 100);
                    }}
                    sx={{ pl: 4 }}
                  >
                    <ListItemText primary={<FormattedMessage id={item.id} />} />
                  </ListItemButton>
                )}
                {index < menuItems[selectedMenu].length - 1 && <Divider />}
              </React.Fragment>
            ))}
        </List>
      </Menu>
    </List>
  );
};

export default NavBar;
