/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("./Models/cinturonTraviz.gltf");
  return (
    <group ref={group} {...props} dispose={null} position={[0, -1, 0]}>
      <mesh geometry={nodes.Plastico001.geometry}>
        <meshStandardMaterial
          color={0x000000}
          envMapIntensity={1.2}
          metalness={0.5}
          roughness={0.3}
        />
      </mesh>
      <mesh geometry={nodes.Correa001_1.geometry}>
        <meshStandardMaterial
          color={0x383838}
          envMapIntensity={1.2}
          metalness={0.5}
          roughness={0.3}
        />
      </mesh>
      <mesh geometry={nodes.Metal001_1.geometry}>
        <meshStandardMaterial
          color={0xbababa}
          envMapIntensity={1.2}
          metalness={0.8}
          roughness={0.1}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload("./Models/cinturonTraviz.gltf");
