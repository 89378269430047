import * as THREE from "three";
import React, { useRef, useState } from "react";
import { useLoader, useFrame } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { useGLTF } from "@react-three/drei";
import { useSpring, animated } from "@react-spring/three";
import { Select } from "@react-three/postprocessing";
import CinturonNQRQLR from "../components/CinturonNQRQLR";
import Placas from "../components/placas";

export default function NMR({ ...props }) {
  const group = useRef();
  const { nodes } = useGLTF("./Models/NMR.gltf");
  const envMapIntensity = 1.4;

  const PegatinaGloss = useLoader(
    TextureLoader,
    "./Textures/PegatinaGloss.jpg"
  );
  PegatinaGloss.wrapS = THREE.RepeatWrapping;
  PegatinaGloss.wrapT = THREE.RepeatWrapping;

  const PegatinaNormal = useLoader(
    TextureLoader,
    "./Textures/PegatinaNormal.jpg"
  );
  PegatinaNormal.wrapS = THREE.RepeatWrapping;
  PegatinaNormal.wrapT = THREE.RepeatWrapping;

  const daoNQR = useLoader(TextureLoader, "./Textures/v2/jpeg/daoNQR.jpg");
  const daoQLR = useLoader(TextureLoader, "./Textures/v2/jpeg/daoQLR.jpg");

  var fondoHideNQR = null;
  var fondoHideQLR = null;

  if (props.fondo === "./Textures/blanco.hdr") {
    fondoHideNQR = false;
    fondoHideQLR = false;
  } else if (props.fondo !== "./Textures/blanco.hdr") {
    if (props.NQR === true) {
      fondoHideNQR = true;
      fondoHideQLR = false;
    } else if (props.QLR === true) {
      fondoHideNQR = false;
      fondoHideQLR = true;
    }
  }

  var position = [0, -1.05, 0];

  const myMesh = React.useRef();
  const myMeshL = React.useRef();
  const myMeshTolva = React.useRef();
  const myMeshHydraulic = React.useRef();
  const myMeshHydraulic2 = React.useRef();
  const [active, setActive] = useState(false);
  const [activeL, setActiveL] = useState(false);
  const [activeTolva, setActiveTolva] = useState(false);
  const [activeHydraulic, setActiveHydraulic] = useState(false);
  const [activeHydraulic2, setActiveHydraulic2] = useState(false);

  const { rotation } = useSpring({
    rotation: active ? [0, 1.047, 0] : [0, 0, 0],
  });
  const { rotationL } = useSpring({
    rotationL: activeL ? [0, -1.047, 0] : [0, 0, 0],
  });
  const { rotationTolva } = useSpring({
    rotationTolva: activeTolva ? [0, 0, 0.994] : [0, 0, 0],
  });
  const { rotationHydraulic } = useSpring({
    rotationHydraulic: activeHydraulic ? [0, 0, -0.754] : [0, 0, 0],
  });
  const { rotationHydraulic2 } = useSpring({
    rotationHydraulic2: activeHydraulic2 ? [0, 0, -0.799] : [0, 0, 0],
  });

  const [hovered, set] = useState(false);
  const [hoveredL, setL] = useState(false);
  const [hoveredTolva, setTolva] = useState(false);
  useFrame(({ clock }) => {});

  const dao01 = useLoader(TextureLoader, "./Textures/traviz/dao01.jpg");
  dao01.flipY = false;

  const rao01 = useLoader(TextureLoader, "./Textures/traviz/rao01.jpg");
  rao01.flipY = false;

  const dao01NMR = useLoader(TextureLoader, "./Textures/NMR/dao01NMR.jpg");
  dao01NMR.flipY = false;

  const rao01NMR = useLoader(TextureLoader, "./Textures/NMR/rao01NMR.jpg");
  rao01NMR.flipY = false;

  const n01 = useLoader(TextureLoader, "./Textures/traviz/n01.png");
  n01.flipY = false;

  const dao02 = useLoader(TextureLoader, "./Textures/traviz/dao02.jpg");
  dao02.flipY = false;

  const dao02NQRQLR = useLoader(TextureLoader, "./Textures/NQRQLR/dao02.jpg");
  dao02NQRQLR.flipY = false;

  const rao02 = useLoader(TextureLoader, "./Textures/traviz/rao02.jpg");
  rao02.flipY = false;

  const rao02NQRQLR = useLoader(TextureLoader, "./Textures/NQRQLR/rao02.jpg");
  rao02NQRQLR.flipY = false;

  const dao03 = useLoader(TextureLoader, "./Textures/traviz/dao03.jpg");
  dao03.flipY = false;

  const dao03NQRQLR = useLoader(TextureLoader, "./Textures/NQRQLR/dao03.jpg");
  dao03NQRQLR.flipY = false;

  const rao03 = useLoader(TextureLoader, "./Textures/traviz/rao03.jpg");
  rao03.flipY = false;

  const rao03NQRQLR = useLoader(TextureLoader, "./Textures/NQRQLR/rao03.jpg");
  rao03NQRQLR.flipY = false;

  const dao04 = useLoader(TextureLoader, "./Textures/traviz/dao04.jpg");
  dao04.flipY = false;

  const rao04 = useLoader(TextureLoader, "./Textures/traviz/rao04.jpg");
  rao04.flipY = false;

  const FarosNormal = useLoader(TextureLoader, "./Textures/FarosNormal.png");

  const FarosRelieveAnchoNormal = useLoader(
    TextureLoader,
    "./Textures/FarosRelieveAnchoNormal.png"
  );
  FarosRelieveAnchoNormal.wrapS = THREE.RepeatWrapping;
  FarosRelieveAnchoNormal.wrapT = THREE.RepeatWrapping;
  FarosRelieveAnchoNormal.repeat.set(3, 3);

  const FarosRelieveFinoNormal = useLoader(
    TextureLoader,
    "./Textures/FarosRelieveFinoNormal.png"
  );
  FarosRelieveFinoNormal.wrapS = THREE.RepeatWrapping;
  FarosRelieveFinoNormal.wrapT = THREE.RepeatWrapping;
  FarosRelieveFinoNormal.repeat.set(8, 8);

  const pano = useLoader(TextureLoader, "./Textures/pano.jpg");
  pano.flipY = true;

  var PinturaBlanca = new THREE.MeshPhysicalMaterial({
    metalness: 0.2,
    envMapIntensity: envMapIntensity,
  });

  var PinturaBlanca2 = new THREE.MeshPhysicalMaterial({
    map: dao01NMR,
    roughnessMap: rao01NMR,
    metalness: 0.2,
    envMapIntensity: envMapIntensity,
  });

  var PinturaBlanca1 = new THREE.MeshPhysicalMaterial({
    metalness: 0.2,
    clearcoat: 1,
    envMapIntensity: envMapIntensity,
    map: dao02NQRQLR,
    roughnessMap: rao02NQRQLR,
    side: THREE.DoubleSide,
  });

  var FondoBlancoNQR = new THREE.MeshStandardMaterial({
    map: daoNQR,
    lightMap: daoNQR,
    envMapIntensity: envMapIntensity,
  });

  var FondoBlancoQLR = new THREE.MeshStandardMaterial({
    map: daoQLR,
    lightMap: daoQLR,
    envMapIntensity: envMapIntensity,
  });

  var PegatinaBlanca = new THREE.MeshStandardMaterial({
    color: 0xe0e0e0,
    envMapIntensity: envMapIntensity,
    roughnessMap: PegatinaGloss,
    metalnessMap: PegatinaGloss,
    normalMap: PegatinaNormal,
    roughness: 0.2,
    metalness: 0.3,
  });
  var PegatinaRoja = new THREE.MeshStandardMaterial({
    color: 0xcf1313,
    envMapIntensity: envMapIntensity,
    roughnessMap: PegatinaGloss,
    metalnessMap: PegatinaGloss,

    roughness: 0.2,
    metalness: 0.3,
  });
  var PinturaNegraMetal = new THREE.MeshStandardMaterial({
    roughness: 0.5,
    envMapIntensity: 1,
    metalness: 0.5,
    map: dao03NQRQLR,
    roughnessMap: rao03NQRQLR,
    color: 0x222222,
  });
  var PinturaGisMetal = new THREE.MeshStandardMaterial({
    roughness: 0.5,
    envMapIntensity: 1,
    metalness: 0.8,
    side: THREE.DoubleSide,
    color: 0xb6b6b6,
  });
  var PinturaNegraMetal2 = new THREE.MeshStandardMaterial({
    roughness: 0.5,
    envMapIntensity: 1,
    metalness: 0.5,
    map: dao02NQRQLR,
    roughnessMap: rao02NQRQLR,
    side: THREE.DoubleSide,
    color: 0x222222,
  });
  var Chromado = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    roughness: 0.2,
    //lightMap: ao02,
    envMapIntensity: envMapIntensity,
    metalness: 1,
  });
  var PlasticoNegro = new THREE.MeshStandardMaterial({
    color: 0x000000,
    roughness: 0.5,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
    map: dao03NQRQLR,
    roughnessMap: rao03NQRQLR,
  });
  var PlasticoNegro2 = new THREE.MeshStandardMaterial({
    color: 0x000000,
    roughness: 0.5,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
    map: dao02NQRQLR,
    roughnessMap: rao02NQRQLR,
  });
  var PlasticoGrisOscuro = new THREE.MeshStandardMaterial({
    roughness: 0.6,
    color: 0x909292,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
    map: dao03NQRQLR,
    roughnessMap: rao03NQRQLR,
  });
  var PlasticoInteriorBrillante = new THREE.MeshStandardMaterial({
    map: dao04,
    roughnessMap: rao04,
    roughness: 0,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var LogoChromado = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    roughness: 0.1,
    envMapIntensity: envMapIntensity,
    metalness: 1,
  });
  var VidriosOscuros = new THREE.MeshPhysicalMaterial({
    color: 0xa1a1a1, //0xa1a1a1,
    transmission: 1,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0,
    metalness: 0.1,
    thickness: 0.01,
    specularIntensity: 1,
    specularColor: 0x000000,
    side: THREE.DoubleSide,
  });
  var GomaNegra = new THREE.MeshStandardMaterial({
    color: 0x000000,
    envMapIntensity: envMapIntensity,
    roughness: 0.5,
    metalness: 1,
  });
  var Escape = new THREE.MeshStandardMaterial({
    roughnessMap: rao03,
    roughness: 0.5,
    envMapIntensity: 0.3,
    metalness: 1,
    side: THREE.DoubleSide,
  });
  var MetalGris = new THREE.MeshPhysicalMaterial({
    color: 0xb6b6b6,
    metalness: 0.8,
    envMapIntensity: envMapIntensity,
    roughness: 0.5,
    side: THREE.DoubleSide,
  });
  var LogoGris = new THREE.MeshPhysicalMaterial({
    color: 0x1c1c1c,
    roughness: 0.2,
    metalness: 0.7,
    clearcoat: 1,
    clearcoatRoughness: 0,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var LogoRojo = new THREE.MeshPhysicalMaterial({
    color: 0xff0000,
    roughness: 0.2,
    metalness: 0.7,
    clearcoat: 1,
    clearcoatRoughness: 0,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var Cauchos = new THREE.MeshStandardMaterial({
    map: dao01,
    roughnessMap: rao01,
    envMapIntensity: envMapIntensity,
    roughness: 0.65,
    color: 0x000000,
    normalMap: n01,
    side: THREE.DoubleSide,
  });
  var Baterias = new THREE.MeshStandardMaterial({
    envMapIntensity: envMapIntensity,
    map: dao03NQRQLR,
    roughnessMap: rao03NQRQLR,
  });
  var Espejo = new THREE.MeshPhysicalMaterial({
    color: 0xffffff,
    metalness: 0.85,
    roughness: 0.3,
    envMapIntensity: 1,
  });
  var PlasticoMicasFrontal = new THREE.MeshPhysicalMaterial({
    color: 0xffffff,
    transmission: 1,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0,
    metalness: 0,
    thickness: 0.1,
    side: THREE.DoubleSide,
  });
  var PlasticoMicasFrontalRelieve = new THREE.MeshPhysicalMaterial({
    color: 0xffffff,
    transmission: 0.7,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.05,
    metalness: 0.4,
    thickness: 0.04,
    specularIntensity: 1,
    specularColor: 0xffffff,
    normalMap: FarosRelieveAnchoNormal,
    side: THREE.DoubleSide,
  });
  var PlasticoMicasFrontalRelieveFino = new THREE.MeshPhysicalMaterial({
    color: 0xdbdbdb,
    transmission: 0.55,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.05,
    metalness: 0,
    thickness: 0.05,
    specularIntensity: 1,
    specularColor: 0xffffff,
    normalMap: FarosRelieveFinoNormal,
    side: THREE.DoubleSide,
  });
  var PlasticoBlancoR = new THREE.MeshStandardMaterial({
    color: 0xe0e0e0,
    envMapIntensity: envMapIntensity,
    roughness: 0.3,
    metalness: 0.9,
    side: THREE.DoubleSide,
  });
  var PlasticoBlanco = new THREE.MeshPhysicalMaterial({
    color: 0x7d7d7d,
    transmission: 0.1,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    thickness: 0,
    normalMap: FarosNormal,
  });
  var PlasticoBlancoM = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    thickness: 0,
    normalMap: FarosNormal,
    metalness: 0,
    opacity: 1,
  });
  var PlasticoAmarilloM = new THREE.MeshStandardMaterial({
    color: 0xd18f00,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    thickness: 0,
    normalMap: FarosNormal,
    metalness: 1,
  });
  var PlasticoAmarillo = new THREE.MeshPhysicalMaterial({
    color: 0x854700,
    transmission: 0.1,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    thickness: 0,
    normalMap: FarosNormal,
  });
  var PlasticoRojo = new THREE.MeshPhysicalMaterial({
    color: 0x850000,
    transmission: 0.1,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    thickness: 0,
    normalMap: FarosNormal,
  });
  var PlasticoRojoM = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    thickness: 0,
    normalMap: FarosNormal,
    metalness: 1,
  });
  var PlasticoBlancoFaro = new THREE.MeshPhysicalMaterial({
    color: 0x7d7d7d,
    transmission: 0,
    opacity: 0.5,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    metalness: 0.14,
    thickness: 0.1,
    specularIntensity: 1,
    specularColor: 0xffffff,
    normalMap: FarosNormal,
    //side: THREE.DoubleSide,
  });
  var PlasticoRojoFaro = new THREE.MeshPhysicalMaterial({
    color: 0x8f4305,
    transmission: 0,
    opacity: 0.5,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    metalness: 0.14,
    thickness: 0.1,
    specularIntensity: 1,
    specularColor: 0xffffff,
    normalMap: FarosNormal,
    side: THREE.DoubleSide,
  });
  var AlfombraGris = new THREE.MeshStandardMaterial({
    envMapIntensity: envMapIntensity,
    color: 0x000000,
    side: THREE.DoubleSide,
    map: dao03NQRQLR,
    roughnessMap: rao03NQRQLR,
  });
  var PlasticoGris = new THREE.MeshStandardMaterial({
    color: 0xa09c98,
    roughness: 0.6,
    envMapIntensity: envMapIntensity,
    map: dao03NQRQLR,
    roughnessMap: rao03NQRQLR,
  });
  var TelaAzul = new THREE.MeshStandardMaterial({
    map: dao04,
    roughnessMap: rao04,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var TelaGris = new THREE.MeshStandardMaterial({
    map: dao04,
    roughnessMap: rao04,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var TelaGris2 = new THREE.MeshStandardMaterial({
    color: 0x726c69,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var Material = new THREE.MeshStandardMaterial();

  return (
    <>
      <group ref={group} {...props} dispose={null} position={position}>
        <mesh
          geometry={nodes.GomaNegra_NMR.geometry}
          material={GomaNegra}
        ></mesh>

        <mesh
          geometry={nodes.GomaNegra2_NMR.geometry}
          material={GomaNegra}
        ></mesh>
        <mesh
          geometry={nodes.GomaNegraNQR_NMR.geometry}
          material={GomaNegra}
        ></mesh>

        <mesh
          geometry={nodes.PlasticoAmarillo2_NMR.geometry}
          material={PlasticoAmarilloM}
        ></mesh>

        <mesh
          geometry={nodes.PlasticoBlanco2_NMR.geometry}
          material={PlasticoBlancoM}
        ></mesh>

        <mesh
          geometry={nodes.PlasticoMicasFrontal_NMR.geometry}
          material={PlasticoMicasFrontalRelieve}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoMicasFrontalRelieve_NMR.geometry}
          material={PlasticoMicasFrontal}
        ></mesh>

        <mesh geometry={nodes.Espejo_NMR.geometry} material={Espejo}></mesh>

        <Select name="puertaR" enabled={hovered}>
          <animated.mesh
            position={[2.156, 0.708, 0.882]}
            rotation={rotation}
            onClick={() => setActive(!active)}
            ref={myMesh}
            scale={[1, 1, -1]}
            onPointerOver={() => {
              set(true);
            }}
            onPointerOut={() => {
              set(false);
            }}
          >
            <mesh
              geometry={nodes.EspejoR_NMR.geometry}
              material={Espejo}
            ></mesh>
            <mesh
              geometry={nodes.PinturaBlancaMetalR_NMR.geometry}
              material={PinturaBlanca1}
            ></mesh>

            <mesh
              geometry={nodes.PlasticoNegroR_NMR.geometry}
              material={PlasticoNegro}
            ></mesh>
            <mesh
              geometry={nodes.VidriosOscurosR_NMR.geometry}
              material={VidriosOscuros}
            ></mesh>

            <mesh
              geometry={nodes.ChromadoR_NMR.geometry}
              material={Chromado}
            ></mesh>
            <mesh
              geometry={nodes.GomaNegraR_NMR.geometry}
              material={GomaNegra}
            ></mesh>
            <mesh
              geometry={nodes.GomaNegra2R_NMR.geometry}
              material={GomaNegra}
            ></mesh>
            <mesh
              geometry={nodes.PlasticoGrisR_NMR.geometry}
              material={PlasticoGris}
            ></mesh>
            <mesh
              geometry={nodes.PlasticoNegroR_NMR.geometry}
              material={PlasticoNegro}
            ></mesh>
            <mesh
              geometry={nodes.PlasticoMicasFrontalR_NMR.geometry}
              material={PlasticoMicasFrontalRelieve}
            ></mesh>
            <mesh
              geometry={nodes.PinturaNegraMetalR_NMR.geometry}
              material={PinturaNegraMetal}
              scale={[1, 1, -1]}
            ></mesh>
          </animated.mesh>
        </Select>

        <Select name="puertaL" enabled={hoveredL}>
          <animated.mesh
            position={[2.156, 0.708, -0.882]}
            rotation={rotationL}
            onClick={() => setActiveL(!activeL)}
            ref={myMeshL}
            onPointerOver={() => {
              setL(true);
            }}
            onPointerOut={() => {
              setL(false);
            }}
          >
            <mesh
              geometry={nodes.PinturaBlancaMetalL_NMR.geometry}
              material={PinturaBlanca1}
            ></mesh>

            <mesh
              geometry={nodes.EspejoL_NMR.geometry}
              material={Espejo}
            ></mesh>

            <mesh
              geometry={nodes.PlasticoNegroL_NMR.geometry}
              material={PlasticoNegro}
            ></mesh>
            <mesh
              geometry={nodes.VidriosOscurosL_NMR.geometry}
              material={VidriosOscuros}
            ></mesh>

            <mesh
              geometry={nodes.ChromadoL_NMR.geometry}
              material={Chromado}
            ></mesh>
            <mesh
              geometry={nodes.GomaNegraL_NMR.geometry}
              material={GomaNegra}
            ></mesh>
            <mesh
              geometry={nodes.GomaNegra2L_NMR.geometry}
              material={GomaNegra}
            ></mesh>
            <mesh
              geometry={nodes.PlasticoGrisL_NMR.geometry}
              material={PlasticoGris}
            ></mesh>
            <mesh
              geometry={nodes.PlasticoNegroL_NMR.geometry}
              material={PlasticoNegro}
            ></mesh>
            <mesh
              geometry={nodes.PlasticoMicasFrontalL_NMR.geometry}
              material={PlasticoMicasFrontalRelieve}
            ></mesh>
            <mesh
              geometry={nodes.PinturaNegraMetalL_NMR.geometry}
              material={PinturaNegraMetal}
            ></mesh>
          </animated.mesh>
        </Select>

        <mesh
          geometry={nodes.ChromadoL_NMR.geometry}
          material={Chromado}
        ></mesh>

        <mesh
          geometry={nodes.VidriosOscuros_NMR.geometry}
          material={VidriosOscuros}
        ></mesh>
        <mesh
          geometry={nodes.VidriosOscurosNQR_NMR.geometry}
          material={VidriosOscuros}
        ></mesh>
        <mesh geometry={nodes.Cauchos1_NMR.geometry} material={Cauchos}></mesh>
        <mesh
          geometry={nodes.CauchosMove_NMR.geometry}
          material={Cauchos}
        ></mesh>
        <mesh geometry={nodes.Cauchos3_NMR.geometry} material={Cauchos}></mesh>
        <mesh
          geometry={nodes.CauchosFalseMove_NMR.geometry}
          material={Cauchos}
        ></mesh>
        <mesh geometry={nodes.Chromado_NMR.geometry} material={Chromado}></mesh>
        <mesh
          geometry={nodes.ChromadoNQR_NMR.geometry}
          material={Chromado}
        ></mesh>
        <mesh
          geometry={nodes.ChromadoMove1_NMR.geometry}
          material={Chromado}
        ></mesh>

        <mesh
          geometry={nodes.LogoChromado_NMR.geometry}
          material={LogoChromado}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoNegro1_NMR.geometry}
          material={PlasticoNegro}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoNegro2_NMR.geometry}
          material={PlasticoNegro}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoNegro3_NMR.geometry}
          material={PlasticoNegro}
        ></mesh>

        <mesh
          geometry={nodes.PinturaBlancaMetal1_NMR.geometry}
          material={PinturaBlanca1}
        ></mesh>
        <mesh
          geometry={nodes.PinturaBlancaMetal3_NMR.geometry}
          material={PinturaBlanca1}
        ></mesh>
        <mesh
          geometry={nodes.PinturaBlancaMetal4_NMR.geometry}
          material={MetalGris}
        ></mesh>
        <mesh
          geometry={nodes.PinturaBlancaMetal5_NMR.geometry}
          material={MetalGris}
        ></mesh>
        <mesh
          geometry={nodes.PinturaBlancaMetalfalse_NMR.geometry}
          material={MetalGris}
        ></mesh>
        <mesh
          geometry={nodes.PinturaBlancaMetalNQR1_NMR.geometry}
          material={PinturaBlanca1}
        ></mesh>
        <mesh
          geometry={nodes.PinturaBlancaMetalNQR2_NMR.geometry}
          material={PinturaBlanca1}
        ></mesh>
        <mesh
          geometry={nodes.PinturaBlancaMetalNQR3_NMR.geometry}
          material={PinturaBlanca1}
        ></mesh>
        <mesh
          geometry={nodes.PinturaBlancaMetalMove_NMR.geometry}
          material={MetalGris}
        ></mesh>

        <mesh
          geometry={nodes.PinturaNegraMetal_NMR.geometry}
          material={PinturaNegraMetal}
        ></mesh>
        <mesh
          geometry={nodes.PinturaNegraMetal2_NMR.geometry}
          material={PinturaNegraMetal2}
        ></mesh>
        <mesh
          geometry={nodes.PinturaNegraMetalNQR1_NMR.geometry}
          material={PinturaNegraMetal}
        ></mesh>
        <mesh
          geometry={nodes.PinturaNegraMetalNQR2_NMR.geometry}
          material={PinturaNegraMetal}
        ></mesh>

        <mesh
          geometry={nodes.PinturaNegraMetalMove2_NMR.geometry}
          material={PinturaNegraMetal}
        ></mesh>

        <mesh geometry={nodes.Escape_NMR.geometry} material={Escape}></mesh>

        <mesh geometry={nodes.Baterias_NMR.geometry} material={Baterias}></mesh>
        <mesh
          geometry={nodes.PlasticoGris_NMR.geometry}
          material={PlasticoGris}
        ></mesh>
        <mesh
          geometry={nodes.Alfombra_NMR.geometry}
          material={AlfombraGris}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoAmarilloM_NMR.geometry}
          material={PlasticoAmarilloM}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoGrisOscuro_NMR.geometry}
          material={PlasticoGrisOscuro}
        ></mesh>
        <mesh
          geometry={nodes.PlastinoNegroBrillante_NMR.geometry}
          material={PlasticoInteriorBrillante}
        ></mesh>
        <mesh geometry={nodes.TelaAzul_NMR.geometry} material={TelaAzul}></mesh>
        <mesh geometry={nodes.TelaGris_NMR.geometry} material={TelaGris}></mesh>
        <mesh
          geometry={nodes.TelaGris2_NMR.geometry}
          material={TelaGris2}
        ></mesh>
        <mesh
          geometry={nodes.PinturaBlancaMetal6_NMR.geometry}
          material={PinturaBlanca2}
        ></mesh>

        <Select name="tolva" enabled={hoveredTolva}>
          <animated.mesh
            position={[-2.267, 0.834, 0]}
            rotation={rotationTolva}
            onClick={() => {
              setActiveTolva(!activeTolva);
              setActiveHydraulic(!activeHydraulic);
              setActiveHydraulic2(!activeHydraulic2);
            }}
            ref={myMeshTolva}
            onPointerOver={() => {
              setTolva(true);
            }}
            onPointerOut={() => {
              setTolva(false);
            }}
          >
            <mesh
              geometry={nodes.PinturaNegraMetal_Tolva2_NMR.geometry}
              material={PinturaNegraMetal}
            ></mesh>
            <mesh
              geometry={nodes.PinturaBlancaMetal_Tolva_NMR.geometry}
              material={PinturaBlanca2}
            ></mesh>
            <mesh
              geometry={nodes.ChromadoMove2_NMR.geometry}
              material={Chromado}
            ></mesh>
            <mesh
              geometry={nodes.PlasticoNegroMove_NMR.geometry}
              material={PlasticoNegro}
            ></mesh>
            <mesh
              geometry={nodes.EspejoMove_NMR.geometry}
              material={Espejo}
            ></mesh>
            <mesh
              geometry={nodes.PinturaNegraMetalMove_NMR.geometry}
              material={PinturaNegraMetal}
            ></mesh>
            <mesh
              geometry={nodes.PlasticoAmarillo_NMR.geometry}
              material={PlasticoAmarillo}
            ></mesh>
            <mesh
              geometry={nodes.PlasticoBlanco_NMR.geometry}
              material={PlasticoBlanco}
            ></mesh>
            <mesh
              geometry={nodes.PlasticoRojo_NMR.geometry}
              material={PlasticoRojo}
            ></mesh>
          </animated.mesh>

          <animated.mesh
            position={[-0.915, 0.76, 0]}
            rotation={rotationHydraulic}
            ref={myMeshHydraulic}
          >
            <mesh
              geometry={nodes.hydraulic_Tolva_NMR.geometry}
              material={PinturaNegraMetal}
            ></mesh>
            <mesh
              geometry={nodes.hydraulicChrome_Tolva_NMR.geometry}
              material={Chromado}
            ></mesh>
          </animated.mesh>

          <animated.mesh
            position={[-0.915, 0.76, 0]}
            rotation={rotationHydraulic2}
            ref={myMeshHydraulic2}
          >
            <mesh
              geometry={nodes.PinturaNegraMetal_Tolva_NMR.geometry}
              material={PinturaNegraMetal}
            ></mesh>
          </animated.mesh>
        </Select>

        <mesh
          geometry={nodes.PinturaNegraMetal_NPR.geometry}
          material={PinturaNegraMetal}
        ></mesh>
      </group>
      <CinturonNQRQLR position={position} />
      <Placas position={[2.39, -0.54, 0]} camion={"NMR"} />
    </>
  );
}
useGLTF.preload("./Models/NMR.gltf");
