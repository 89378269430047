import { useGLTF } from "@react-three/drei";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import Anotacion from "./Anotacion";

const Anotaciones = ({ activeMensajes, activeIdioma, ...Props }) => {
  const { nodes } = useGLTF("./Models/QLRlow.gltf");
  const myMesh = React.useRef();
  const disx = 0;
  const disz = -1.05;

  //Interface para mover puntos de interes

  return (
    <group>
      <mesh
        ref={myMesh}
        geometry={nodes.lowQLR.geometry}
        position={[0 + disx, 0 + disz, 0]}
        visible={false}
      >
        <meshStandardMaterial color={0xffffff} />
      </mesh>
      <Anotacion
        FloatPointText1={<FormattedMessage id="QLR77E.halogen.Title" />}
        FloatPointText2={<FormattedMessage id="QLR77E.halogen.Text" />}
        FloatPointPosition={[2.37 + disx, 0.81 + disz, 0.82]}
        myMesh={myMesh}
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        positi
      />
      <Anotacion
        FloatPointText1={<FormattedMessage id="QLR77E.giro.Title" />}
        FloatPointText2={<FormattedMessage id="QLR77E.giro.Text" />}
        FloatPointPosition={[1.97 + disx, 0.4 + disz, 1]}
        myMesh={myMesh}
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={
          <FormattedMessage
            id={"QLR77E.asientos.Title"}
            defaultMessage={"null"}
          />
        }
        FloatPointText2={
          <FormattedMessage
            id={"QLR77E.asientos.Text"}
            defaultMessage={"null"}
          />
        }
        FloatPointPosition={[2.38 + disx, 1.49 + disz, 0]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="QLR77E.motor.Title" />}
        FloatPointText2={
          <ul>
            <li>
              <FormattedMessage id="QLR77E.motor.Text1" />
            </li>
            <li>
              <FormattedMessage id="QLR77E.motor.Text2" />
            </li>
            <li>
              <FormattedMessage id="QLR77E.motor.Text3" />
            </li>
            <li>
              <FormattedMessage id="QLR77E.motor.Text4" />
            </li>
          </ul>
        }
        FloatPointPosition={[0.74 + disx, 1.05 + disz, -0.017]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="QLR77E.trasmision.Title" />}
        FloatPointText2={
          <>
            <FormattedMessage id="QLR77E.trasmision.Text1" />
          </>
        }
        FloatPointPosition={[-1 + disx, 0.84 + disz, 0]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="QLR77E.frenotrasero.Title" />}
        FloatPointText2={<FormattedMessage id="QLR77E.frenotrasero.Text" />}
        FloatPointPosition={[-1.09 + disx, 0.37 + disz, 0.94]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="QLR77E.tanque.Title" />}
        FloatPointText2={<FormattedMessage id="QLR77E.tanque.Text" />}
        FloatPointPosition={[-0.31 + disx, 0.46 + disz, 0.92]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="QLR77E.cabina.Title" />}
        FloatPointText2={<FormattedMessage id="QLR77E.cabina.Text" />}
        FloatPointPosition={[1.48 + disx, 2.36 + disz, -0.017]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="QLR77E.aire.Title" />}
        FloatPointText2={
          <ul>
            <li>
              <FormattedMessage id="QLR77E.aire.Text1" />
            </li>
            <li>
              <FormattedMessage id="QLR77E.aire.Text2" />
            </li>
          </ul>
        }
        FloatPointPosition={[2.49 + disx, 0.9 + disz, 0]}
        myMesh={myMesh}
      />

      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="QLR77E.cinturon.Title" />}
        FloatPointText2={
          <ul>
            <li>
              <FormattedMessage id="QLR77E.cinturon.Text1" />
            </li>
            <li>
              <FormattedMessage id="QLR77E.cinturon.Text2" />
            </li>
          </ul>
        }
        FloatPointPosition={[1.04 + disx, 1.93 + disz, 0.66]}
        myMesh={myMesh}
      />
    </group>
  );
};

export default Anotaciones;
useGLTF.preload("./Models/QLRlow.gltf");
