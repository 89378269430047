import React, { useContext, useRef } from "react";
import useSlider from "../hooks/useSlider";
import ArrowRight from "@mui/icons-material/ArrowRight";
import ArrowLeft from "@mui/icons-material/ArrowLeft";
import { AppContext } from "../context/AppContext";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const Slider = ({ images }) => {
  const slideImage = useRef(null);
  const { goToPreviousSlide, goToNextSlide } = useSlider(slideImage, images);

  const global = useContext(AppContext);
  var slider = null;

  switch (global.activeSlider) {
    case 1:
      slider = (
        <div class="container1">
          <img
            className="Logocamion"
            src="./Media/q-series.svg"
            alt="Logocamion"
          />
          <div class="Camion1">
            <Tooltip title="QLR 77L" followCursor className="tool">
              <Link to="/QLR77L">
                <div className="div-camiones">
                  <img className="img-camion" src="./Media/QLR.png" alt="QLR" />
                </div>
              </Link>
            </Tooltip>
          </div>
          <div class="Camion2">
            <Tooltip title="QMR 77L" followCursor className="tool">
              <Link to="/QMR77L">
                <div className="div-camiones">
                  <img className="img-camion" src="./Media/QMR.png" alt="QMR" />
                </div>
              </Link>
            </Tooltip>
          </div>
        </div>
      );
      break;
    case 0:
      slider = (
        <div class="container2">
          <img
            className="Logocamion"
            src="./Media/p-series.svg"
            alt="Logocamion"
          />
          <div class="Camion3">
            <Tooltip title="Traviz" followCursor className="tool">
              <Link to="/Traviz">
                <div className="div-camiones">
                  <img
                    className="img-camion"
                    src="./Media/traviz.png"
                    alt="Traviz"
                  />
                </div>
              </Link>
            </Tooltip>
          </div>
          <div class="Camion4">
            <Tooltip title="Traviz flat Bed" followCursor className="tool">
              <Link to="/Traviz-flat-Bed">
                <div className="div-camiones">
                  <img
                    className="img-camion"
                    src="./Media/traviz2.png"
                    alt="Traviz2"
                  />
                </div>
              </Link>
            </Tooltip>
          </div>
        </div>
      );
      break;
    case 2:
      slider = (
        <div class="container3">
          <img
            className="Logocamion"
            src="./Media/n-series.svg"
            alt="Logocamion"
          />
          <div class="Camion5">
            <Tooltip title="NQR 90L" followCursor className="tool">
              <Link to="/NQR90L">
                <div className="div-camiones">
                  <img
                    className="img-camion"
                    src="./Media/NQR2.png"
                    alt="NQR"
                  />
                </div>
              </Link>
            </Tooltip>
          </div>
          <div class="Camion6">
            <Tooltip title="NPR 71L" followCursor className="tool">
              <Link to="/NPR">
                <div className="div-camiones">
                  <img className="img-camion" src="./Media/NPR.png" alt="NPR" />
                </div>
              </Link>
            </Tooltip>
          </div>
          <div class="Camion7">
            <Tooltip title="NMR" followCursor className="tool">
              <Link to="/NMR">
                <div className="div-camiones">
                  <img className="img-camion" src="./Media/NMR.png" alt="NMR" />
                </div>
              </Link>
            </Tooltip>
          </div>
        </div>
      );
      break;
    case 3:
      slider = (
        <div class="container4">
          <img
            className="Logocamion"
            src="./Media/f-series.svg"
            alt="Logocamion"
          />
          <div class="Camion8">
            <Tooltip title="FVR" followCursor className="tool">
              <Link to="/FVR">
                <div className="div-camiones">
                  <img className="img-camion" src="./Media/FVR.png" alt="FVR" />
                </div>
              </Link>
            </Tooltip>
          </div>
        </div>
      );
      break;
    default:
  }

  return (
    <div className="slider" ref={slideImage}>
      <div className="slider--content">
        {global.activeSlider > 0 && (
          <button onClick={goToPreviousSlide} className="slider__btn-left">
            <img
              className="camionflecha"
              src={images[global.activeSlider - 1].src}
              alt="Logolatin"
            />
            <ArrowLeft id="flechas" />
          </button>
        )}
        <div className="slider--feature">
          <img
            className="Logolatin"
            src="./Media/logolatin.svg"
            alt="Logolatin"
          />

          <h1 className="feature--title">
            <FormattedMessage id="slider.tittle1" />
          </h1>
          <h1 className="feature--text">
            {/*<FormattedMessage id="slider.tittle2" />*/}
          </h1>
        </div>
        {global.activeSlider < images.length - 1 && (
          <button onClick={goToNextSlide} className="slider__btn-right">
            <img
              className="camionflecha"
              src={images[global.activeSlider + 1].src}
              alt="Logolatin"
            />
            <ArrowRight id="flechas" />
          </button>
        )}
        {slider}
      </div>
    </div>
  );
};

export default Slider;
