import * as THREE from "three";
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { TextureLoader } from "three";
import { useLoader } from "@react-three/fiber";

export default function Model({ fondo, camion, ...props }) {
  const group = useRef();
  const { nodes } = useGLTF("./Models/pabellon.gltf");
  var ambiente = useLoader(TextureLoader, "./Textures/ao_p.jpg");
  var ambiente2 = useLoader(TextureLoader, "./Textures/v2/jpeg/fondo_1.jpg");
  var traviz = useLoader(TextureLoader, "./Textures/v2/jpeg/dao00.jpg");
  var QLRQMR = useLoader(TextureLoader, "./Textures/v2/jpeg/daoQLR.jpg");
  var NQR = useLoader(TextureLoader, "./Textures/v2/jpeg/daoNQR.jpg");
  var FVR = useLoader(TextureLoader, "./Textures/v2/jpeg/daoFVR.jpg");
  var sombra = null;

  var sombraTraviz = false;
  var sombraQLR = false;
  var sombraNQR = false;

  if (camion === "Traviz") {
    sombra = traviz;
    sombraTraviz = true;
  } else if (camion === "Traviz Flat Bed") {
    sombra = traviz;
    sombraTraviz = true;
  } else if (camion === "QLR 77L") {
    sombra = QLRQMR;
    sombraQLR = true;
  } else if (camion === "QMR 77L") {
    sombra = QLRQMR;
    sombraQLR = true;
  } else if (camion === "NQR 90L") {
    sombra = NQR;
    sombraNQR = true;
  } else if (camion === "NPR") {
    sombra = NQR;
    sombraNQR = true;
  } else if (camion === "NMR") {
    sombra = QLRQMR;
    sombraQLR = true;
  } else if (camion === "FVR") {
    sombra = FVR;
    sombraNQR = true;
  }

  var fondoHide = false;
  var pabellonHide = false;
  var sombraMap = false;
  var sombraColor = false;
  if (fondo === "./Textures/blanco.hdr") {
    fondoHide = false;
    pabellonHide = true;
    sombraMap = null;
    sombraColor = 0xffffff;
  } else if (fondo !== "./Textures/blanco.hdr") {
    fondoHide = true;
    pabellonHide = false;
    sombraMap = ambiente2;
    sombraColor = 0xababab;
  }

  ambiente.flipY = false;
  var fondom = new THREE.MeshStandardMaterial({
    map: ambiente,
    envMapIntensity: 1.2,
  });

  var fondo2 = new THREE.MeshBasicMaterial({
    color: 0xababab,
    map: ambiente2,
  });

  var piso = new THREE.MeshBasicMaterial({
    color: sombraColor,
    map: sombraMap,
    lightMap: sombra,
  });

  var letranegra = new THREE.MeshStandardMaterial({
    color: 0x000000,
    envMapIntensity: 1.2,
  });

  var letraroja = new THREE.MeshStandardMaterial({
    color: 0xff0000,
    envMapIntensity: 1.2,
  });

  var emisivo = new THREE.MeshStandardMaterial({
    emissive: 0xffffff,
    emissiveIntensity: 50,
  });

  var verde = new THREE.MeshBasicMaterial({
    color: 0x0000ff,
  });

  return (
    <group ref={group} dispose={null} position={[0, -1.04, 0]}>
      <group visible={pabellonHide}>
        <mesh geometry={nodes.columnas.geometry} material={fondom} />
        <mesh geometry={nodes.emisivo.geometry} material={emisivo} />
        <mesh geometry={nodes.luces.geometry} material={fondom} />
        <mesh geometry={nodes.paredcurva.geometry} material={fondom} />
        <mesh geometry={nodes.pisoparedes.geometry} material={fondom} />
        <mesh geometry={nodes.letrasrojas.geometry} material={letraroja} />
        <mesh geometry={nodes.letrasnegras.geometry} material={letranegra} />
      </group>

      <group visible={fondoHide}>
        <mesh geometry={nodes.Fondo1.geometry} material={fondo2} />
      </group>

      <mesh
        geometry={nodes.sombraTraviz.geometry}
        material={piso}
        visible={sombraTraviz}
      />
      <mesh
        geometry={nodes.sombraNQR.geometry}
        material={piso}
        visible={sombraNQR}
      />
      <mesh
        geometry={nodes.sombraQLR.geometry}
        material={piso}
        visible={sombraQLR}
      />
    </group>
  );
}

useGLTF.preload("./Models/pabellon.gltf");
