import { useGLTF } from "@react-three/drei";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import Anotacion from "./Anotacion";
import { AppContext } from "../context/AppContext";
import { useContext } from "react";

const Anotaciones = ({ activeMensajes, activeIdioma, vista, ...Props }) => {
  const global = useContext(AppContext);
  const { nodes } = useGLTF("./Models/FVRlow.gltf");
  const myMesh = React.useRef();
  var disx = 1;
  const disz = -1.05;

  if (vista === "Interior") {
    disx = -10;
  } else if (vista === "Exterior") {
    disx = 1;
  }

  //Interface para mover puntos de interes

  return (
    <group>
      <mesh
        ref={myMesh}
        geometry={nodes.low_FVR.geometry}
        position={[-1 + disx, 0 + disz, 0]}
        visible={false}
      >
        <meshStandardMaterial color={0xffffff} />
      </mesh>
      <Anotacion
        FloatPointText1={<FormattedMessage id="FVR.halogen.Title" />}
        FloatPointText2={<FormattedMessage id="FVR.halogen.Text" />}
        FloatPointPosition={[2.95 + disx, 1.2 + disz, 0.82]}
        myMesh={myMesh}
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
      />
      <Anotacion
        FloatPointText1={<FormattedMessage id="FVR.giro.Title" />}
        FloatPointText2={<FormattedMessage id="FVR.giro.Text" />}
        FloatPointPosition={[2.5 + disx, 0.8 + disz, 1.1]}
        myMesh={myMesh}
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={
          <FormattedMessage id={"FVR.asientos.Title"} defaultMessage={"null"} />
        }
        FloatPointText2={
          <FormattedMessage id={"FVR.asientos.Text"} defaultMessage={"null"} />
        }
        FloatPointPosition={[3 + disx, 1.7 + disz, 0]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="FVR.motor.Title" />}
        FloatPointText2={
          <ul>
            <li>
              <FormattedMessage id="FVR.motor.Text1" />
            </li>
            <li>
              <FormattedMessage id="FVR.motor.Text2" />
            </li>
            <li>
              <FormattedMessage id="FVR.motor.Text3" />
            </li>
            <li>
              <FormattedMessage id="FVR.motor.Text4" />
            </li>
          </ul>
        }
        FloatPointPosition={[1 + disx, 1.4 + disz, -0.017]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="FVR.transmision.Title" />}
        FloatPointText2={
          <>
            <FormattedMessage id="FVR.transmision.Text1" />
          </>
        }
        FloatPointPosition={[-1 + disx, 1.2 + disz, 0]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="FVR.frenotrasero.Title" />}
        FloatPointText2={<FormattedMessage id="FVR.frenotrasero.Text" />}
        FloatPointPosition={[-3 + disx, 0.37 + disz, 1.25]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="FVR.filtrodeaire.Title" />}
        FloatPointText2={<FormattedMessage id="FVR.filtrodeaire.Text" />}
        FloatPointPosition={[0.95 + disx, 1.5 + disz, 0.6]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="FVR.cabina.Title" />}
        FloatPointText2={<FormattedMessage id="FVR.cabina.Text" />}
        FloatPointPosition={[1.48 + disx, 2.7 + disz, 0]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="FVR.aire.Title" />}
        FloatPointText2={<FormattedMessage id="FVR.aire.Text" />}
        FloatPointPosition={[3 + disx, 1 + disz, 0]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="FVR.lucesfreno.Title" />}
        FloatPointText2={<FormattedMessage id="FVR.lucesfreno.Text" />}
        FloatPointPosition={[-5.5 + disx, 0.99 + disz, 0]}
        myMesh={myMesh}
      />
    </group>
  );
};

export default Anotaciones;
useGLTF.preload("./Models/FVRlow.gltf");
