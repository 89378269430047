import * as THREE from "three";
import { useRef, Suspense, useContext, useEffect } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import {
  Environment,
  CameraControls,
  PerspectiveCamera,
} from "@react-three/drei";
import Pabellon from "./Pabellon";
import Loading from "./Loading";
import NavBar from "./NavBar";
import PositionedMenu from "./PositionedMenu";
import {
  EffectComposer,
  Outline,
  Selection,
} from "@react-three/postprocessing";
import { AppContext } from "../context/AppContext";
import { FormattedMessage } from "react-intl";

export default function Interfaz({
  camion,
  anotaciones,
  camionActive,
  serieActive,
  camionDistanciaExterior,
  camionPosicionInterior,
  camionZoom,
  camionAltura,
  ...props
}) {
  const global = useContext(AppContext);

  useEffect(() => {
    global.setActiveCamion(camionActive);
    global.setActiveSerie(serieActive);
  });

  var zoom = null;
  var dis = null;
  var pos = null;
  var rotate = null;
  var near = null;
  var minAzimuthAngle = null;
  var maxAzimuthAngle = null;
  var minPolarAngle = null;
  var maxPolarAngle = null;

  switch (global.activeVista) {
    case "Exterior":
      zoom = camionZoom;
      dis = camionDistanciaExterior;
      pos = { x: 0, y: camionAltura, z: 0 };
      rotate = { azimuth: 1.2, polar: 1.5708 };
      near = 4;
      minAzimuthAngle = -Infinity;
      maxAzimuthAngle = Infinity;
      minPolarAngle = 1.3;
      maxPolarAngle = 1.6;
      break;
    case "Interior":
      zoom = 0.8;
      dis = 0.01;
      pos = { x: camionPosicionInterior.x, y: camionPosicionInterior.y, z: 0 };
      rotate = { azimuth: 4.71239, polar: 1 };
      near = 0.1;
      minAzimuthAngle = 3.5;
      maxAzimuthAngle = 5.9;
      minPolarAngle = 1;
      maxPolarAngle = 1.6;
      break;
    default:
      break;
  }

  return (
    <>
      <Loading />
      <Suspense
        fallback={
          <>
            {global.showIdioma ? null : (
              <div className="loading" style={{ backgroundColor: "white" }}>
                <img className="Logo" src="./Media/logo.svg" alt="Logo" />
                <span id="cargando-id">
                  <FormattedMessage id="loader.loading" />
                </span>
              </div>
            )}
          </>
        }
      >
        <PositionedMenu />
        <NavBar camion={global.activeCamion} />
        <div className="canvas">
          <Canvas camera={{ position: [0, 0, 0], fov: 60 }}>
            <ambientLight color={"0xffffff"} intensity={0.1} />
            <Environment files={global.activeFondo} background />
            {anotaciones}
            <Selection>
              <EffectComposer multisampling={4} autoClear={false}>
                <Outline
                  visibleEdgeColor="white"
                  hiddenEdgeColor="white"
                  blur
                  edgeStrength={5}
                />
              </EffectComposer>
              {camion}
            </Selection>
            <Pabellon fondo={global.activeFondo} camion={global.activeCamion} />
            <Camara
              zoom={zoom}
              dis={dis}
              pos={pos}
              rotate={rotate}
              minAzimuthAngle={minAzimuthAngle}
              maxAzimuthAngle={maxAzimuthAngle}
              minPolarAngle={minPolarAngle}
              maxPolarAngle={maxPolarAngle}
              near={near}
            />
          </Canvas>
        </div>
      </Suspense>
    </>
  );
}

function Camara({
  zoom,
  dis,
  pos,
  rotate,
  maxAzimuthAngle,
  minAzimuthAngle,
  maxPolarAngle,
  minPolarAngle,
  near,
  ...props
}) {
  const cameraControlsRef = useRef();

  const { camera } = useThree();
  camera.near = near;
  useEffect(() => {
    cameraControlsRef.current?.zoomTo(zoom, false);
    cameraControlsRef.current?.setLookAt(dis, 0, 0, pos.x, pos.y, pos.z, false);
    cameraControlsRef.current?.rotateTo(rotate.azimuth, rotate.polar, true);
    cameraControlsRef.current?.dolly(dis, false);
  });

  return (
    <CameraControls
      ref={cameraControlsRef}
      distance={dis}
      minDistance={dis - 0.01}
      maxDistance={dis + 0.01}
      minZoom={0.1}
      maxZoom={3.1}
      enabled={true}
      verticalDragToForward={false}
      dollyToCursor={false}
      infinityDolly={false}
      minPolarAngle={minPolarAngle}
      maxPolarAngle={maxPolarAngle}
      minAzimuthAngle={minAzimuthAngle}
      maxAzimuthAngle={maxAzimuthAngle}
    />
  );
}
