import { useGLTF } from "@react-three/drei";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import Anotacion from "./Anotacion";

const Anotaciones = ({ activeMensajes, activeIdioma, vista, ...Props }) => {
  const { nodes } = useGLTF("./Models/NQRlow.gltf");
  const myMesh = React.useRef();
  var disx = 1;
  const disz = -1.05;

  if (vista === "Interior") {
    disx = 0;
  } else if (vista === "Exterior") {
    disx = 1;
  }

  //Interface para mover puntos de interes

  return (
    <group>
      <mesh
        ref={myMesh}
        geometry={nodes.lowNQR.geometry}
        position={[0 + disx, 0 + disz, 0]}
        visible={false}
      >
        <meshStandardMaterial color={0xffffff} />
      </mesh>
      <Anotacion
        FloatPointText1={<FormattedMessage id="NQR90L.halogen.Title" />}
        FloatPointText2={<FormattedMessage id="NQR90L.halogen.Text" />}
        FloatPointPosition={[2.37 + disx, 0.81 + disz, 0.82]}
        myMesh={myMesh}
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        positi
      />
      <Anotacion
        FloatPointText1={<FormattedMessage id="NQR90L.giro.Title" />}
        FloatPointText2={<FormattedMessage id="NQR90L.giro.Text" />}
        FloatPointPosition={[1.97 + disx, 0.4 + disz, 1]}
        myMesh={myMesh}
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={
          <FormattedMessage
            id={"NQR90L.asientos.Title"}
            defaultMessage={"null"}
          />
        }
        FloatPointText2={
          <FormattedMessage
            id={"NQR90L.asientos.Text"}
            defaultMessage={"null"}
          />
        }
        FloatPointPosition={[2.38 + disx, 1.49 + disz, 0]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="NQR90L.motor.Title" />}
        FloatPointText2={
          <ul>
            <li>
              <FormattedMessage id="NQR90L.motor.Text1" />
            </li>
            <li>
              <FormattedMessage id="NQR90L.motor.Text2" />
            </li>
            <li>
              <FormattedMessage id="NQR90L.motor.Text3" />
            </li>
          </ul>
        }
        FloatPointPosition={[0.74 + disx, 1.05 + disz, -0.017]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="NQR90L.transmision.Title" />}
        FloatPointText2={
          <>
            <FormattedMessage id="NQR90L.transmision.Text1" />
          </>
        }
        FloatPointPosition={[-1 + disx, 0.84 + disz, -0.017]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="NQR90L.frenotrasero.Title" />}
        FloatPointText2={<FormattedMessage id="NQR90L.frenotrasero.Text" />}
        FloatPointPosition={[-2.5 + disx, 0.37 + disz, 1.15]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="NQR90L.filtrodeaire.Title" />}
        FloatPointText2={<FormattedMessage id="NQR90L.filtrodeaire.Text" />}
        FloatPointPosition={[0.66 + disx, 0.56 + disz, 0.92]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="NQR90L.cabina.Title" />}
        FloatPointText2={<FormattedMessage id="NQR90L.cabina.Text" />}
        FloatPointPosition={[1.48 + disx, 2.36 + disz, -0.017]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="NQR90L.aire.Title" />}
        FloatPointText2={
          <ul>
            <li>
              <FormattedMessage id="NQR90L.aire.Text1" />
            </li>
            <li>
              <FormattedMessage id="NQR90L.aire.Text2" />
            </li>
          </ul>
        }
        FloatPointPosition={[2.49 + disx, 0.9 + disz, 0]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="NQR90L.lucesfreno.Title" />}
        FloatPointText2={<FormattedMessage id="NQR90L.lucesfreno.Text" />}
        FloatPointPosition={[-4.17 + disx, 0.99 + disz, 0]}
        myMesh={myMesh}
      />
    </group>
  );
};

export default Anotaciones;
useGLTF.preload("./Models/NQRlow.gltf");
