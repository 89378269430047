import { useGLTF } from "@react-three/drei";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import Anotacion from "./Anotacion";

const Anotaciones = ({ activeMensajes, activeIdioma, ...Props }) => {
  const { nodes } = useGLTF("./Models/travizlow.gltf");
  const myMesh = React.useRef();

  return (
    <group>
      <mesh
        ref={myMesh}
        geometry={nodes.lowtraviz.geometry}
        position={[0, -1, 0]}
        visible={false}
      >
        <meshStandardMaterial color={0xffffff} />
      </mesh>
      <Anotacion
        FloatPointText1={<FormattedMessage id="traviz.Bateria.Title" />}
        FloatPointText2={<FormattedMessage id="traviz.Bateria.Text" />}
        FloatPointPosition={[-0.067, -0.493, 0.765]}
        myMesh={myMesh}
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={
          <FormattedMessage
            id={"traviz.CinturonDeSeguridad.Title"}
            defaultMessage={"null"}
          />
        }
        FloatPointText2={
          <FormattedMessage
            id={"traviz.CinturonDeSeguridad.Text"}
            defaultMessage={"null"}
          />
        }
        FloatPointPosition={[0.955, 0.7, 0.668]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="traviz.Deposito.Title" />}
        FloatPointText2={<FormattedMessage id="traviz.Deposito.Text" />}
        FloatPointPosition={[0.178, -0.545, -0.957]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="traviz.Diferencial.Title" />}
        FloatPointText2={<FormattedMessage id="traviz.Diferencial.Text" />}
        FloatPointPosition={[-1.227, -0.719, -0.008]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="traviz.FrenoTrasero.Title" />}
        FloatPointText2={<FormattedMessage id="traviz.FrenoTrasero.Text" />}
        FloatPointPosition={[-1.148, -0.704, 0.987]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="traviz.Motor.Title" />}
        FloatPointText2={
          <ul>
            <li>
              <FormattedMessage id="traviz.Motor.Text1" />
            </li>
            <li>
              <FormattedMessage id="traviz.Motor.Text2" />
            </li>
            <li>
              <FormattedMessage id="traviz.Motor.Text3" />
            </li>
          </ul>
        }
        FloatPointPosition={[0.786, 0.297, -0.067]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={
          <FormattedMessage id="traviz.NeumaticoTrasero.Title" />
        }
        FloatPointText2={<FormattedMessage id="traviz.NeumaticoTrasero.Text" />}
        FloatPointPosition={[-0.891, -0.735, -0.915]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="traviz.Repuesto.Title" />}
        FloatPointText2={<FormattedMessage id="traviz.Repuesto.Text" />}
        FloatPointPosition={[-1.839, -0.82, -0.021]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="traviz.Suspencion.Title" />}
        FloatPointText2={<FormattedMessage id="traviz.Suspencion.Text" />}
        FloatPointPosition={[-1.187, 0.094, -0.063]}
        myMesh={myMesh}
      />
      <Anotacion
        activeIdioma={activeIdioma}
        activeMensajes={activeMensajes}
        FloatPointText1={<FormattedMessage id="traviz.Transmision.Title" />}
        FloatPointText2={<FormattedMessage id="traviz.Transmision.Text" />}
        FloatPointPosition={[0.16, -0.735, -0.1]}
        myMesh={myMesh}
      />
    </group>
  );
};

export default Anotaciones;
useGLTF.preload("./Models/travizlow.gltf");
