import { useContext, useEffect } from "react";
import { AppContext } from "../context/AppContext";

const useSlider = (slideImage, images) => {
  const global = useContext(AppContext);

  useEffect(() => handleSlide(global.activeSlider));

  const handleSlide = (slide) => {
    slideImage.current.style.backgroundImage = `linear-gradient(
 to right,
 rgba(34, 34, 34, 0),
 rgba(68, 68, 68, 0)
 ), url(./Media/fondo.jpg)`;
    animateSlide(slideImage);
  };

  const animateSlide = () => {
    slideImage.current.classList.add("fadeIn");
    setTimeout(() => {
      slideImage.current.classList.remove("fadeIn");
    }, 700);
  };

  const goToPreviousSlide = () => {
    global.setSlider(global.activeSlider - 1);
    console.log(0);
  };

  const goToNextSlide = () => {
    global.setSlider(global.activeSlider + 1);
    console.log(1);
  };

  return { goToPreviousSlide, goToNextSlide };
};

export default useSlider;
