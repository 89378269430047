import React, { useState } from "react";
import MensajesEspañol from "../lenguajes/es.json";
import MensajesIngles from "../lenguajes/en.json";
import { IntlProvider } from "react-intl";

export const AppContext = React.createContext({});

export default function AppContextProvider({ children }) {
  const [activeFondo, setActiveFondo] = useState("./Textures/blanco.hdr");
  const [activeVista, setActiveVista] = useState("Exterior");
  const [activeCamion, setActiveCamion] = useState("Traviz");
  const [activeSerie, setActiveSerie] = useState(null);
  const [showIdioma, setShowIdioma] = useState(true);
  const [activeMensajes, setMensajes] = useState(MensajesEspañol);
  const [activeIdioma, setIdioma] = useState("es");
  const [activeSlider, setSlider] = useState(0);
  const [showCamiones, setShowCamiones] = useState(true);
  const [showAn, setShowAn] = useState(false);
  const [openDis, setOpenDis] = useState(true);
  console.log(activeCamion);
  //Función para establecer el idioma y los mensajes dependiendo del valor que traiga de "lenguaje" al oprimir los botones en la pantalla de seleccion de idioma.
  const establecerIdioma = (lenguaje) => {
    switch (lenguaje) {
      case "es":
        setMensajes(MensajesEspañol);
        setIdioma("es");
        break;
      case "en":
        setMensajes(MensajesIngles);
        setIdioma("en");
        break;
      default:
        setMensajes(MensajesEspañol);
        setIdioma("es");
    }
  };

  return (
    <AppContext.Provider
      value={{
        activeFondo,
        setActiveFondo,
        activeVista,
        setActiveVista,
        activeCamion,
        setActiveCamion,
        showIdioma,
        setShowIdioma,
        establecerIdioma,
        activeSlider,
        setSlider,
        showCamiones,
        setShowCamiones,
        showAn,
        setShowAn,
        setMensajes,
        activeMensajes,
        activeIdioma,
        setIdioma,
        openDis,
        setOpenDis,
        activeSerie,
        setActiveSerie,
      }}
    >
      {/*Proveedor para el idioma activo y sus mensajes*/}
      <IntlProvider locale={activeIdioma} messages={activeMensajes}>
        {children}
      </IntlProvider>
    </AppContext.Provider>
  );
}
