import * as THREE from "three";
import React, { useContext, useRef, useState } from "react";
import { useLoader, useFrame } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { H, useGLTF } from "@react-three/drei";
import { useSpring, animated } from "@react-spring/three";
import { AppContext } from "../context/AppContext";
import { Select } from "@react-three/postprocessing";
import { useEffect } from "react";
import Placas from "../components/placas";

export default function Traviz({ ...props }) {
  const group = useRef();
  const { nodes } = useGLTF("./Models/traviz.gltf");
  const envMapIntensity = 1.4;

  const PegatinaGloss = useLoader(
    TextureLoader,
    "./Textures/PegatinaGloss.jpg"
  );
  PegatinaGloss.wrapS = THREE.RepeatWrapping;
  PegatinaGloss.wrapT = THREE.RepeatWrapping;

  const PegatinaNormal = useLoader(
    TextureLoader,
    "./Textures/PegatinaNormal.jpg"
  );
  PegatinaNormal.wrapS = THREE.RepeatWrapping;
  PegatinaNormal.wrapT = THREE.RepeatWrapping;

  const dao00 = useLoader(TextureLoader, "./Textures/v2/jpeg/dao00.jpg");

  var ambiente = useLoader(TextureLoader, "./Textures/v2/jpeg/fondo_1.jpg");

  var fondoHide = true;
  if (props.fondo === "./Textures/blanco.hdr") {
    ambiente = null;
    fondoHide = false;
  }

  const myMesh = React.useRef();
  const myMeshL = React.useRef();
  const [active, setActive] = useState(false);
  const [activeL, setActiveL] = useState(false);

  const { rotation } = useSpring({
    rotation: active ? [0, 1.047, 0] : [0, 0, 0],
  });
  const { rotationL } = useSpring({
    rotationL: activeL ? [0, -1.047, 0] : [0, 0, 0],
  });

  const [hovered, set] = useState(false);
  const [hoveredL, setL] = useState(false);

  useFrame(({ clock }) => {});

  const dao01 = useLoader(TextureLoader, "./Textures/traviz/dao01.jpg");
  dao01.flipY = false;

  const rao01 = useLoader(TextureLoader, "./Textures/traviz/rao01.jpg");
  rao01.flipY = false;

  const n01 = useLoader(TextureLoader, "./Textures/traviz/n01.png");
  n01.flipY = false;

  const dao02 = useLoader(TextureLoader, "./Textures/traviz/dao02.jpg");
  dao02.flipY = false;

  const rao02 = useLoader(TextureLoader, "./Textures/traviz/rao02.jpg");
  rao02.flipY = false;

  const dao03 = useLoader(TextureLoader, "./Textures/traviz/dao03.jpg");
  dao03.flipY = false;

  const rao03 = useLoader(TextureLoader, "./Textures/traviz/rao03.jpg");
  rao03.flipY = false;

  const dao04 = useLoader(TextureLoader, "./Textures/traviz/dao04.jpg");
  dao04.flipY = false;

  const rao04 = useLoader(TextureLoader, "./Textures/traviz/rao04.jpg");
  rao04.flipY = false;

  const FarosNormal = useLoader(TextureLoader, "./Textures/FarosNormal.png");

  const FarosRelieveAnchoNormal = useLoader(
    TextureLoader,
    "./Textures/FarosRelieveAnchoNormal.png"
  );
  FarosRelieveAnchoNormal.wrapS = THREE.RepeatWrapping;
  FarosRelieveAnchoNormal.wrapT = THREE.RepeatWrapping;
  FarosRelieveAnchoNormal.repeat.set(3, 3);

  const FarosRelieveFinoNormal = useLoader(
    TextureLoader,
    "./Textures/FarosRelieveFinoNormal.png"
  );
  FarosRelieveFinoNormal.wrapS = THREE.RepeatWrapping;
  FarosRelieveFinoNormal.wrapT = THREE.RepeatWrapping;
  FarosRelieveFinoNormal.repeat.set(8, 8);

  var PinturaBlanca2 = new THREE.MeshPhysicalMaterial({
    map: dao02,
    roughnessMap: rao02,
    metalness: 0.2,
    clearcoat: 1,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });

  var PinturaBlanca1 = new THREE.MeshPhysicalMaterial({
    map: dao02,
    roughnessMap: rao02,
    metalness: 0.3,
    clearcoat: 1,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });

  var PinturaBlanca3 = new THREE.MeshPhysicalMaterial({
    metalness: 0.3,
    clearcoat: 1,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });

  var FondoBlanco = new THREE.MeshStandardMaterial({
    map: dao00,
    lightMap: dao00,
    envMapIntensity: envMapIntensity,
  });

  var PegatinaBlanca = new THREE.MeshStandardMaterial({
    color: 0xe0e0e0,
    envMapIntensity: envMapIntensity,
    roughnessMap: PegatinaGloss,
    metalnessMap: PegatinaGloss,
    normalMap: PegatinaNormal,
    roughness: 0.2,
    metalness: 0.3,
  });
  var PegatinaRoja = new THREE.MeshStandardMaterial({
    color: 0xcf1313,
    envMapIntensity: envMapIntensity,
    roughnessMap: PegatinaGloss,
    metalnessMap: PegatinaGloss,
    roughness: 0.2,
    metalness: 0.3,
  });
  var PinturaNegraMetal = new THREE.MeshStandardMaterial({
    map: dao03,
    roughnessMap: rao03,
    roughness: 0.3,
    envMapIntensity: 1,
    metalness: 0.9,
    side: THREE.DoubleSide,
  });
  var Chromado = new THREE.MeshStandardMaterial({
    color: 0x909292,
    roughness: 0,
    //lightMap: ao02,
    envMapIntensity: envMapIntensity,
    metalness: 1,
  });
  var PlasticoNegro = new THREE.MeshStandardMaterial({
    map: dao03,
    lightMap: dao03,
    color: 0x000000,
    roughnessMap: rao03,
    roughness: 0.5,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var PlasticoInterior = new THREE.MeshStandardMaterial({
    map: dao04,
    roughnessMap: rao04,
    roughness: 0.6,
    color: 0x909292,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var PlasticoInteriorBrillante = new THREE.MeshStandardMaterial({
    map: dao04,
    roughnessMap: rao04,
    roughness: 0,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var LogoChromado = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    roughness: 0.1,
    envMapIntensity: envMapIntensity,
    metalness: 1,
  });
  var VidriosOscuros = new THREE.MeshPhysicalMaterial({
    color: 0xa1a1a1, //0xa1a1a1,
    transmission: 1,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0,
    metalness: 0.1,
    thickness: 0.01,
    specularIntensity: 1,
    specularColor: 0x000000,
    side: THREE.DoubleSide,
  });
  var GomaNegra = new THREE.MeshStandardMaterial({
    color: 0x000000,
    envMapIntensity: envMapIntensity,
    roughness: 0.5,
    metalness: 1,
  });
  var Escape = new THREE.MeshStandardMaterial({
    roughnessMap: rao03,
    roughness: 0.5,
    envMapIntensity: 0.3,
    metalness: 1,
    side: THREE.DoubleSide,
  });
  var MetalGris = new THREE.MeshPhysicalMaterial({
    map: dao02,
    lightMap: dao02,
    roughnessMap: rao02,
    color: 0x909292,
    metalness: 0.4,
    clearcoat: 1,
    clearcoatRoughness: 0.2,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var LogoGris = new THREE.MeshPhysicalMaterial({
    color: 0x1c1c1c,
    roughness: 0.2,
    metalness: 0.7,
    clearcoat: 1,
    clearcoatRoughness: 0,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var LogoRojo = new THREE.MeshPhysicalMaterial({
    color: 0xff0000,
    roughness: 0.2,
    metalness: 0.7,
    clearcoat: 1,
    clearcoatRoughness: 0,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var Cauchos = new THREE.MeshStandardMaterial({
    map: dao01,
    roughnessMap: rao01,
    envMapIntensity: envMapIntensity,
    roughness: 0.65,
    color: 0x000000,
    normalMap: n01,
  });
  var Baterias = new THREE.MeshStandardMaterial({
    map: dao03,
    lightMap: dao03,
    roughnessMap: rao03,
    envMapIntensity: envMapIntensity,
  });
  var Espejo = new THREE.MeshPhysicalMaterial({
    color: 0xffffff,
    metalness: 0.85,
    roughness: 0,
    envMapIntensity: 1,
  });
  var PlasticoMicasFrontal = new THREE.MeshPhysicalMaterial({
    color: 0xffffff,
    transmission: 1,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0,
    metalness: 0,
    thickness: 0.1,
    side: THREE.DoubleSide,
  });
  var PlasticoMicasFrontalRelieve = new THREE.MeshPhysicalMaterial({
    color: 0xffffff,
    transmission: 0.85,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.05,
    metalness: 0,
    thickness: 0.1,
    specularIntensity: 1,
    specularColor: 0xffffff,
    normalMap: FarosRelieveAnchoNormal,
    side: THREE.DoubleSide,
  });
  var PlasticoMicasFrontalRelieveFino = new THREE.MeshPhysicalMaterial({
    color: 0xdbdbdb,
    transmission: 0.55,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.05,
    metalness: 0,
    thickness: 0.05,
    specularIntensity: 1,
    specularColor: 0xffffff,
    normalMap: FarosRelieveFinoNormal,
    side: THREE.DoubleSide,
  });
  var PlasticoBlancoR = new THREE.MeshStandardMaterial({
    color: 0xe0e0e0,
    envMapIntensity: envMapIntensity,
    roughness: 0.3,
    metalness: 0.9,
    side: THREE.DoubleSide,
  });
  var PlasticoBlanco = new THREE.MeshPhysicalMaterial({
    color: 0x7d7d7d,
    transmission: 0.3,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    thickness: 0,
    normalMap: FarosNormal,
  });
  var PlasticoBlancoM = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    thickness: 0,
    normalMap: FarosNormal,
    metalness: 1,
  });
  var PlasticoAmarilloM = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    thickness: 0,
    normalMap: FarosNormal,
    metalness: 1,
  });
  var PlasticoAmarillo = new THREE.MeshPhysicalMaterial({
    color: 0x854700,
    transmission: 0.3,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    thickness: 0,
    normalMap: FarosNormal,
  });
  var PlasticoRojo = new THREE.MeshPhysicalMaterial({
    color: 0x850000,
    transmission: 0.3,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    thickness: 0,
    normalMap: FarosNormal,
  });
  var PlasticoRojoM = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    thickness: 0,
    normalMap: FarosNormal,
    metalness: 1,
  });
  var PlasticoBlancoFaro = new THREE.MeshPhysicalMaterial({
    color: 0x7d7d7d,
    transmission: 0,
    opacity: 0.5,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    metalness: 0.14,
    thickness: 0.1,
    specularIntensity: 1,
    specularColor: 0xffffff,
    normalMap: FarosNormal,
    //side: THREE.DoubleSide,
  });
  var PlasticoRojoFaro = new THREE.MeshPhysicalMaterial({
    color: 0x8f4305,
    transmission: 0,
    opacity: 0.5,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    metalness: 0.14,
    thickness: 0.1,
    specularIntensity: 1,
    specularColor: 0xffffff,
    normalMap: FarosNormal,
    side: THREE.DoubleSide,
  });
  var AlfombraGris = new THREE.MeshStandardMaterial({
    map: dao03,
    roughnessMap: rao03,
    envMapIntensity: envMapIntensity,
    color: 0xa09c98,
    side: THREE.DoubleSide,
  });
  var PlasticoGris = new THREE.MeshStandardMaterial({
    map: dao04,
    color: 0xa09c98,
    roughnessMap: rao04,
    roughness: 0.6,
    envMapIntensity: envMapIntensity,
  });
  var TelaAzul = new THREE.MeshStandardMaterial({
    map: dao04,
    roughnessMap: rao04,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var TelaGris = new THREE.MeshStandardMaterial({
    map: dao04,
    roughnessMap: rao04,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });

  const ref1 = useRef();

  return (
    <group ref={group} {...props} dispose={null} position={[0, -1, 0]}>
      {/*<mesh geometry={nodes.Fondo.geometry} visible={fondoHide}>
        <meshStandardMaterial
          color={0xffffff}
          map={ambiente}
          envMapIntensity={1.2}
          aoMap={dao00}
          metalness={1}
        />
      </mesh>*/}
      <mesh geometry={nodes.GomaNegra.geometry} material={GomaNegra}></mesh>
      <mesh
        geometry={nodes.PlasticoAmarillo.geometry}
        material={PlasticoAmarillo}
      ></mesh>
      <mesh
        geometry={nodes.PlasticoBlanco.geometry}
        material={PlasticoBlanco}
      ></mesh>
      <mesh
        geometry={nodes.PlasticoBlancoFaro.geometry}
        material={PlasticoBlancoFaro}
      ></mesh>
      <mesh
        geometry={nodes.PlasticoBlancoR.geometry}
        material={PlasticoBlancoR}
      ></mesh>
      <mesh
        geometry={nodes.PlasticoMicasFrontal.geometry}
        material={PlasticoMicasFrontal}
      ></mesh>
      <mesh
        geometry={nodes.PlasticoMicasFrontalRelieve.geometry}
        material={PlasticoMicasFrontalRelieve}
      ></mesh>
      <mesh
        geometry={nodes.PlasticoMicasFrontalRelieveFino.geometry}
        material={PlasticoMicasFrontalRelieveFino}
      ></mesh>
      <mesh
        geometry={nodes.PlasticoRojo.geometry}
        material={PlasticoRojo}
      ></mesh>
      <mesh
        geometry={nodes.PlasticoRojoFaro.geometry}
        material={PlasticoRojoFaro}
      ></mesh>
      <mesh geometry={nodes.Espejo.geometry} material={Espejo}></mesh>
      <mesh
        geometry={nodes.PegatinaBlanca.geometry}
        material={PegatinaBlanca}
      ></mesh>
      <mesh
        geometry={nodes.PegatinaBlancaFalse.geometry}
        material={PegatinaBlanca}
        visible={props.camionVisible}
      ></mesh>
      <mesh
        geometry={nodes.PegatinaRoja.geometry}
        material={PegatinaRoja}
      ></mesh>
      <mesh
        geometry={nodes.PegatinaRojaFalse.geometry}
        material={PegatinaRoja}
        visible={props.camionVisible}
      ></mesh>
      <mesh geometry={nodes.LogoGris.geometry} material={LogoGris}></mesh>
      <mesh
        geometry={nodes.LogoGrisFalse.geometry}
        material={LogoGris}
        visible={props.camionVisible}
      ></mesh>
      <Select name="puertaR" enabled={hovered}>
        <animated.mesh
          position={[2.06, 1.02, 0.82]}
          rotation={rotation}
          onClick={() => setActive(!active)}
          ref={myMesh}
          onPointerOver={() => {
            set(true);
          }}
          onPointerOut={() => {
            set(false);
          }}
        >
          <mesh
            geometry={nodes.ChromadoPuertaR.geometry}
            material={Chromado}
          ></mesh>
          <mesh
            geometry={nodes.EspejoPuertaR.geometry}
            material={Espejo}
          ></mesh>
          <mesh
            geometry={nodes.GomaNegraPuertaR.geometry}
            material={GomaNegra}
          ></mesh>
          <mesh
            geometry={nodes.LogoGrisPuertaR.geometry}
            material={LogoGris}
          ></mesh>
          <mesh geometry={nodes.LogoRojoR.geometry} material={LogoRojo}></mesh>
          <mesh
            geometry={nodes.PinturaBlancaMetalPuertaR.geometry}
            material={PinturaBlanca2}
          ></mesh>
          <mesh
            geometry={nodes.PlasticoGrisPuertaR.geometry}
            material={PlasticoGris}
          ></mesh>
          <mesh
            geometry={nodes.PlasticoNegroPuertaR.geometry}
            material={PlasticoNegro}
          ></mesh>
          <mesh
            geometry={nodes.VidriosOscurosPuertaR.geometry}
            material={VidriosOscuros}
          ></mesh>
        </animated.mesh>
      </Select>
      <Select name="puertaL" enabled={hoveredL}>
        <animated.mesh
          position={[2.06, 1.02, -0.82]}
          rotation={rotationL}
          onClick={() => setActiveL(!activeL)}
          ref={myMeshL}
          onPointerOver={() => {
            setL(true);
          }}
          onPointerOut={() => {
            setL(false);
          }}
        >
          <mesh
            geometry={nodes.ChromadoPuertaL.geometry}
            material={Chromado}
          ></mesh>
          <mesh
            geometry={nodes.EspejoPuertaL.geometry}
            material={Espejo}
          ></mesh>
          <mesh
            geometry={nodes.GomaNegraPuertaL.geometry}
            material={GomaNegra}
          ></mesh>
          <mesh
            geometry={nodes.LogoGrisPuertaL.geometry}
            material={LogoGris}
          ></mesh>
          <mesh geometry={nodes.LogoRojoL.geometry} material={LogoRojo}></mesh>
          <mesh
            geometry={nodes.PinturaBlancaMetalPuertaL.geometry}
            material={PinturaBlanca2}
          ></mesh>
          <mesh
            geometry={nodes.PlasticoGrisPuertaL.geometry}
            material={PlasticoGris}
          ></mesh>
          <mesh
            geometry={nodes.PlasticoNegroPuertaL.geometry}
            material={PlasticoNegro}
          ></mesh>
          <mesh
            geometry={nodes.VidriosOscurosPuertaL.geometry}
            material={VidriosOscuros}
          ></mesh>
        </animated.mesh>
      </Select>
      <mesh
        geometry={nodes.VidriosOscuros.geometry}
        material={VidriosOscuros}
      ></mesh>
      <mesh geometry={nodes.Cauchos.geometry} material={Cauchos}></mesh>
      <mesh geometry={nodes.Cauchos2.geometry} material={Cauchos}></mesh>
      <mesh geometry={nodes.Chromado.geometry} material={Chromado}></mesh>
      <mesh
        geometry={nodes.ChromadoFalse.geometry}
        material={Chromado}
        visible={props.camionVisible}
      ></mesh>
      <mesh
        geometry={nodes.LogoChromado.geometry}
        material={LogoChromado}
      ></mesh>
      <mesh
        geometry={nodes.PlasticoNegro.geometry}
        material={PlasticoNegro}
      ></mesh>
      <mesh
        geometry={nodes.PlasticoNegro2.geometry}
        material={PlasticoNegro}
      ></mesh>
      <mesh
        geometry={nodes.PlasticoNegroFalse.geometry}
        material={PlasticoNegro}
        visible={props.camionVisible}
      ></mesh>
      <mesh
        geometry={nodes.PinturaBlancaMetal1.geometry}
        material={PinturaBlanca1}
      ></mesh>
      <mesh
        geometry={nodes.PinturaBlancaMetal2.geometry}
        material={PinturaBlanca1}
        visible={props.camionVisible}
        ref={ref1}
      ></mesh>
      <mesh
        geometry={nodes.PinturaBlancaMetal3.geometry}
        material={PinturaBlanca3}
        visible={props.camionVisible}
      ></mesh>
      <mesh
        geometry={nodes.PinturaBlancaMetal4.geometry}
        material={PinturaBlanca1}
      ></mesh>
      <mesh
        geometry={nodes.PinturaBlancaMetal5.geometry}
        material={PinturaBlanca1}
      ></mesh>
      <mesh
        geometry={nodes.PinturaBlancaMetal6.geometry}
        material={PinturaBlanca1}
      ></mesh>
      <mesh
        geometry={nodes.PinturaNegraMetal.geometry}
        material={PinturaNegraMetal}
      ></mesh>
      <mesh geometry={nodes.Escape.geometry} material={Escape}></mesh>
      <mesh geometry={nodes.Baterias.geometry} material={Baterias}></mesh>
      <mesh geometry={nodes.MetalGris.geometry} material={MetalGris}></mesh>
      <mesh
        geometry={nodes.PlasticoGris.geometry}
        material={PlasticoGris}
      ></mesh>
      <mesh
        geometry={nodes.AlfombraGris.geometry}
        material={AlfombraGris}
      ></mesh>
      <mesh geometry={nodes.EspejoRetrovisor.geometry} material={Espejo}></mesh>
      <mesh geometry={nodes.GomaCurva.geometry} material={GomaNegra}></mesh>
      <mesh
        geometry={nodes.PlasticoAmarilloM.geometry}
        material={PlasticoAmarilloM}
      ></mesh>
      <mesh
        geometry={nodes.PlasticoRojoM.geometry}
        material={PlasticoRojoM}
      ></mesh>
      <mesh
        geometry={nodes.PlasticoBlancoM.geometry}
        material={PlasticoBlancoM}
      ></mesh>
      <mesh
        geometry={nodes.PlasticoNegroInterior.geometry}
        material={PlasticoInterior}
      ></mesh>
      <mesh
        geometry={nodes.PlastinoNegroBrillante.geometry}
        material={PlasticoInteriorBrillante}
      ></mesh>
      <mesh geometry={nodes.TelaAzul.geometry} material={TelaAzul}></mesh>
      <mesh geometry={nodes.TelaGris.geometry} material={TelaGris}></mesh>
      <Placas position={[2.335, 0.41, 0]} camion={"Traviz"} />
    </group>
  );
}

useGLTF.preload("./Models/traviz.gltf");

/*

*/
