import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Html } from "@react-three/drei";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";

import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { IntlProvider } from "react-intl";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Anotacion = ({
  FloatPointText1,
  FloatPointText2,
  FloatPointPosition,
  myMesh,
  activeMensajes,
  activeIdioma,
  ...props
}) => {
  const [occluded, occlude] = useState();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <group>
      <mesh position={FloatPointPosition} className="Anotacion">
        <Html
          // Hide contents "behind" other meshes
          //occlude={[myMesh]}
          // Tells us when contents are occluded (or not)
          //onOcclude={occlude}
          // We just interpolate the visible state into css opacity and transforms
          style={{
            transition: "all 0.2s",
            opacity: occluded ? 0 : 1,
            transform: `scale(${occluded ? 0.25 : 1})`,
            zIndex: -10,
          }}
        >
          <IntlProvider locale={activeIdioma} messages={activeMensajes}>
            <div>
              <Tooltip title={FloatPointText1} placement="top">
                <IconButton size="large">
                  <InfoIcon fontSize="inherit" id="info1" />
                  <InfoIcon
                    fontSize="inherit"
                    onClick={handleClickOpen}
                    id="info2"
                  />
                </IconButton>
              </Tooltip>
              <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
              >
                <BootstrapDialogTitle
                  className="anotation-title"
                  onClose={handleClose}
                  sx={{
                    paddingRight: 7,
                    paddingLeft: 2.1,
                  }}
                >
                  {FloatPointText1}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                  <Typography gutterBottom id="anotation-text">
                    {FloatPointText2}
                  </Typography>
                </DialogContent>
              </BootstrapDialog>
            </div>
          </IntlProvider>
        </Html>
      </mesh>
    </group>
  );
};

export default Anotacion;
