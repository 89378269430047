import * as THREE from "three";
import React, { useContext, useRef, useState } from "react";
import { useLoader, useFrame } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { useGLTF } from "@react-three/drei";
import { useSpring, animated } from "@react-spring/three";
import { AppContext } from "../context/AppContext";
import { Select } from "@react-three/postprocessing";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import CinturonNQRQLR from "./CinturonNQRQLR";
import Placas from "./placas";

export default function NQR({ ...props }) {
  const group = useRef();
  const { nodes } = useGLTF("./Models/NQR.gltf");
  const envMapIntensity = 1.4;

  const PegatinaGloss = useLoader(
    TextureLoader,
    "./Textures/PegatinaGloss.jpg"
  );
  PegatinaGloss.wrapS = THREE.RepeatWrapping;
  PegatinaGloss.wrapT = THREE.RepeatWrapping;

  const PegatinaNormal = useLoader(
    TextureLoader,
    "./Textures/PegatinaNormal.jpg"
  );
  PegatinaNormal.wrapS = THREE.RepeatWrapping;
  PegatinaNormal.wrapT = THREE.RepeatWrapping;

  const daoNQR = useLoader(TextureLoader, "./Textures/v2/jpeg/daoNQR.jpg");
  const daoQLR = useLoader(TextureLoader, "./Textures/v2/jpeg/daoQLR.jpg");

  var ambiente = useLoader(TextureLoader, "./Textures/v2/jpeg/fondo_1.jpg");

  var fondoHide = null;
  var fondoHideNQR = null;
  var fondoHideQLR = null;

  if (props.fondo === "./Textures/blanco.hdr") {
    ambiente = null;
    fondoHideNQR = false;
    fondoHideQLR = false;
  } else if (props.fondo !== "./Textures/blanco.hdr") {
    fondoHideNQR = true;
    fondoHideQLR = false;
  }

  var position = null;
  if (props.vista === "Interior") {
    position = [0, -1.05, 0];
  } else if (props.vista === "Exterior") {
    position = [1, -1.05, 0];
  }

  const myMesh = React.useRef();
  const myMeshL = React.useRef();
  const [active, setActive] = useState(false);
  const [activeL, setActiveL] = useState(false);

  const { rotation } = useSpring({
    rotation: active ? [0, 1.047, 0] : [0, 0, 0],
  });
  const { rotationL } = useSpring({
    rotationL: activeL ? [0, -1.047, 0] : [0, 0, 0],
  });

  const [hovered, set] = useState(false);
  const [hoveredL, setL] = useState(false);
  useFrame(({ clock }) => {});

  const dao01 = useLoader(TextureLoader, "./Textures/traviz/dao01.jpg");
  dao01.flipY = false;

  const rao01 = useLoader(TextureLoader, "./Textures/traviz/rao01.jpg");
  rao01.flipY = false;

  const n01 = useLoader(TextureLoader, "./Textures/traviz/n01.png");
  n01.flipY = false;

  const dao02 = useLoader(TextureLoader, "./Textures/traviz/dao02.jpg");
  dao02.flipY = false;

  const dao02NQRQLR = useLoader(TextureLoader, "./Textures/NQRQLR/dao02.jpg");
  dao02NQRQLR.flipY = false;

  const rao02 = useLoader(TextureLoader, "./Textures/traviz/rao02.jpg");
  rao02.flipY = false;

  const rao02NQRQLR = useLoader(TextureLoader, "./Textures/NQRQLR/rao02.jpg");
  rao02NQRQLR.flipY = false;

  const dao03 = useLoader(TextureLoader, "./Textures/traviz/dao03.jpg");
  dao03.flipY = false;

  const dao03NQRQLR = useLoader(TextureLoader, "./Textures/NQRQLR/dao03.jpg");
  dao03NQRQLR.flipY = false;

  const rao03 = useLoader(TextureLoader, "./Textures/traviz/rao03.jpg");
  rao03.flipY = false;

  const rao03NQRQLR = useLoader(TextureLoader, "./Textures/NQRQLR/rao03.jpg");
  rao03NQRQLR.flipY = false;

  const dao04 = useLoader(TextureLoader, "./Textures/traviz/dao04.jpg");
  dao04.flipY = false;

  const rao04 = useLoader(TextureLoader, "./Textures/traviz/rao04.jpg");
  rao04.flipY = false;

  const dao05 = useLoader(TextureLoader, "./Textures/NQR/dao1.jpg");
  dao05.flipY = false;

  const rao05 = useLoader(TextureLoader, "./Textures/NQR/rao1.jpg");
  rao05.flipY = false;

  const FarosNormal = useLoader(TextureLoader, "./Textures/FarosNormal.png");

  const FarosRelieveAnchoNormal = useLoader(
    TextureLoader,
    "./Textures/FarosRelieveAnchoNormal.png"
  );
  FarosRelieveAnchoNormal.wrapS = THREE.RepeatWrapping;
  FarosRelieveAnchoNormal.wrapT = THREE.RepeatWrapping;
  FarosRelieveAnchoNormal.repeat.set(3, 3);

  const FarosRelieveFinoNormal = useLoader(
    TextureLoader,
    "./Textures/FarosRelieveFinoNormal.png"
  );
  FarosRelieveFinoNormal.wrapS = THREE.RepeatWrapping;
  FarosRelieveFinoNormal.wrapT = THREE.RepeatWrapping;
  FarosRelieveFinoNormal.repeat.set(8, 8);

  const pano = useLoader(TextureLoader, "./Textures/pano.jpg");
  pano.flipY = true;

  var PinturaBlanca2 = new THREE.MeshPhysicalMaterial({
    metalness: 0.2,
    clearcoat: 1,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });

  var PinturaBlanca1 = new THREE.MeshPhysicalMaterial({
    metalness: 0.2,
    clearcoat: 1,
    envMapIntensity: envMapIntensity,
    map: dao02NQRQLR,
    roughnessMap: rao02NQRQLR,
    side: THREE.DoubleSide,
  });

  var FondoBlancoNQR = new THREE.MeshStandardMaterial({
    map: daoNQR,
    lightMap: daoNQR,
    envMapIntensity: envMapIntensity,
  });

  var FondoBlancoQLR = new THREE.MeshStandardMaterial({
    map: daoQLR,
    lightMap: daoQLR,
    envMapIntensity: envMapIntensity,
  });

  var PegatinaBlanca = new THREE.MeshStandardMaterial({
    color: 0xe0e0e0,
    envMapIntensity: envMapIntensity,
    roughnessMap: PegatinaGloss,
    metalnessMap: PegatinaGloss,
    normalMap: PegatinaNormal,
    roughness: 0.2,
    metalness: 0.3,
  });
  var PegatinaRoja = new THREE.MeshStandardMaterial({
    color: 0xcf1313,
    envMapIntensity: envMapIntensity,
    roughnessMap: PegatinaGloss,
    metalnessMap: PegatinaGloss,

    roughness: 0.2,
    metalness: 0.3,
  });
  var PinturaNegraMetal = new THREE.MeshStandardMaterial({
    roughness: 0.5,
    envMapIntensity: 1,
    metalness: 0.5,
    map: dao03NQRQLR,
    roughnessMap: rao03NQRQLR,
    side: THREE.DoubleSide,
    color: 0x222222,
  });
  var PinturaGisMetal = new THREE.MeshStandardMaterial({
    roughness: 0.5,
    envMapIntensity: 1,
    metalness: 0.8,
    side: THREE.DoubleSide,
    color: 0xb6b6b6,
  });
  var PinturaNegraMetal2 = new THREE.MeshStandardMaterial({
    roughness: 0.5,
    envMapIntensity: 1,
    metalness: 0.5,
    map: dao02NQRQLR,
    roughnessMap: rao02NQRQLR,
    side: THREE.DoubleSide,
    color: 0x222222,
  });
  var Chromado = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    roughness: 0.2,
    //lightMap: ao02,
    envMapIntensity: envMapIntensity,
    metalness: 1,
  });
  var PlasticoNegro = new THREE.MeshStandardMaterial({
    color: 0x000000,
    roughness: 0.5,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
    map: dao03NQRQLR,
    roughnessMap: rao03NQRQLR,
  });
  var PlasticoNegro2 = new THREE.MeshStandardMaterial({
    color: 0x000000,
    roughness: 0.5,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
    map: dao02NQRQLR,
    roughnessMap: rao02NQRQLR,
  });
  var PlasticoGrisOscuro = new THREE.MeshStandardMaterial({
    roughness: 0.6,
    color: 0x909292,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
    map: dao03NQRQLR,
    roughnessMap: rao03NQRQLR,
  });
  var PlasticoInteriorBrillante = new THREE.MeshStandardMaterial({
    map: dao04,
    roughnessMap: rao04,
    roughness: 0,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var PlasticoInteriorBrillante2 = new THREE.MeshStandardMaterial({
    color: 0x000000,
    roughness: 0.5,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var LogoChromado = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    roughness: 0.1,
    envMapIntensity: envMapIntensity,
    metalness: 1,
  });
  var VidriosOscuros = new THREE.MeshPhysicalMaterial({
    color: 0xa1a1a1, //0xa1a1a1,
    transmission: 1,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0,
    metalness: 0.1,
    thickness: 0.01,
    specularIntensity: 1,
    specularColor: 0x000000,
    side: THREE.DoubleSide,
  });
  var GomaNegra = new THREE.MeshStandardMaterial({
    color: 0x000000,
    envMapIntensity: envMapIntensity,
    roughness: 0.5,
    metalness: 1,
  });
  var Escape = new THREE.MeshStandardMaterial({
    roughnessMap: rao03,
    roughness: 0.5,
    envMapIntensity: 0.3,
    metalness: 1,
    side: THREE.DoubleSide,
  });
  var MetalGris = new THREE.MeshPhysicalMaterial({
    color: 0x606060,
    metalness: 0.8,
    envMapIntensity: envMapIntensity,
    roughness: 0.6,
    side: THREE.DoubleSide,
    map: dao02NQRQLR,
    roughnessMap: rao02NQRQLR,
  });
  var LogoGris = new THREE.MeshPhysicalMaterial({
    color: 0x1c1c1c,
    roughness: 0.2,
    metalness: 0.7,
    clearcoat: 1,
    clearcoatRoughness: 0,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var LogoRojo = new THREE.MeshPhysicalMaterial({
    color: 0xff0000,
    roughness: 0.2,
    metalness: 0.7,
    clearcoat: 1,
    clearcoatRoughness: 0,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var Cauchos = new THREE.MeshStandardMaterial({
    map: dao01,
    roughnessMap: rao01,
    envMapIntensity: envMapIntensity,
    roughness: 0.65,
    color: 0x000000,
    normalMap: n01,
    side: THREE.DoubleSide,
  });
  var Baterias = new THREE.MeshStandardMaterial({
    envMapIntensity: envMapIntensity,
    map: dao03NQRQLR,
    roughnessMap: rao03NQRQLR,
  });
  var Espejo = new THREE.MeshPhysicalMaterial({
    color: 0xffffff,
    metalness: 0.85,
    roughness: 0.3,
    envMapIntensity: 1,
  });
  var PlasticoMicasFrontal = new THREE.MeshPhysicalMaterial({
    color: 0xffffff,
    transmission: 1,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0,
    metalness: 0,
    thickness: 0.1,
    side: THREE.DoubleSide,
  });
  var PlasticoMicasFrontalRelieve = new THREE.MeshPhysicalMaterial({
    color: 0xffffff,
    transmission: 0.7,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.05,
    metalness: 0.4,
    thickness: 0.04,
    specularIntensity: 1,
    specularColor: 0xffffff,
    normalMap: FarosRelieveAnchoNormal,
    side: THREE.DoubleSide,
  });
  var PlasticoMicasFrontalRelieveFino = new THREE.MeshPhysicalMaterial({
    color: 0xdbdbdb,
    transmission: 0.55,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.05,
    metalness: 0,
    thickness: 0.05,
    specularIntensity: 1,
    specularColor: 0xffffff,
    normalMap: FarosRelieveFinoNormal,
    side: THREE.DoubleSide,
  });
  var PlasticoBlancoR = new THREE.MeshStandardMaterial({
    color: 0xe0e0e0,
    envMapIntensity: envMapIntensity,
    roughness: 0.3,
    metalness: 0.9,
    side: THREE.DoubleSide,
  });
  var PlasticoBlanco = new THREE.MeshPhysicalMaterial({
    color: 0x7d7d7d,
    transmission: 0.1,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    thickness: 0,
    normalMap: FarosNormal,
  });
  var PlasticoBlancoM = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    thickness: 0,
    normalMap: FarosNormal,
    metalness: 0,
    opacity: 1,
  });
  var PlasticoAmarilloM = new THREE.MeshStandardMaterial({
    color: 0xd18f00,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    thickness: 0,
    normalMap: FarosNormal,
    metalness: 1,
  });
  var PlasticoAmarillo = new THREE.MeshPhysicalMaterial({
    color: 0x854700,
    transmission: 0.1,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    thickness: 0,
    normalMap: FarosNormal,
  });
  var PlasticoRojo = new THREE.MeshPhysicalMaterial({
    color: 0x850000,
    transmission: 0.1,
    opacity: 1,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    thickness: 0,
    normalMap: FarosNormal,
  });
  var PlasticoRojoM = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    thickness: 0,
    normalMap: FarosNormal,
    metalness: 1,
  });
  var PlasticoBlancoFaro = new THREE.MeshPhysicalMaterial({
    color: 0x7d7d7d,
    transmission: 0,
    opacity: 0.5,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    metalness: 0.14,
    thickness: 0.1,
    specularIntensity: 1,
    specularColor: 0xffffff,
    normalMap: FarosNormal,
    //side: THREE.DoubleSide,
  });
  var PlasticoRojoFaro = new THREE.MeshPhysicalMaterial({
    color: 0x8f4305,
    transmission: 0,
    opacity: 0.5,
    ior: 1.5,
    envMapIntensity: envMapIntensity,
    roughness: 0.08,
    metalness: 0.14,
    thickness: 0.1,
    specularIntensity: 1,
    specularColor: 0xffffff,
    normalMap: FarosNormal,
    side: THREE.DoubleSide,
  });
  var AlfombraGris = new THREE.MeshStandardMaterial({
    envMapIntensity: envMapIntensity,
    color: 0x000000,
    side: THREE.DoubleSide,
    map: dao03NQRQLR,
    roughnessMap: rao03NQRQLR,
  });
  var PlasticoGris = new THREE.MeshStandardMaterial({
    color: 0xa09c98,
    roughness: 0.6,
    envMapIntensity: envMapIntensity,
    map: dao03NQRQLR,
    roughnessMap: rao03NQRQLR,
  });
  var TelaAzul = new THREE.MeshStandardMaterial({
    color: 0xb8b8b8,
    roughness: 0.8,
    map: dao05,
    roughnessMap: rao05,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var TelaGris = new THREE.MeshStandardMaterial({
    map: dao05,
    roughnessMap: rao05,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var TelaGris2 = new THREE.MeshStandardMaterial({
    color: 0x726c69,
    envMapIntensity: envMapIntensity,
    side: THREE.DoubleSide,
  });
  var Pano = new THREE.MeshStandardMaterial({
    map: pano,
    color: 0xffffff,
    side: THREE.DoubleSide,
    metalness: 1,
  });
  var PlasticoRojoTacometro = new THREE.MeshStandardMaterial({
    color: 0xa09c98,
    roughness: 0.6,
    envMapIntensity: envMapIntensity,
    map: dao05,
    roughnessMap: rao05,
  });

  return (
    <>
      <group ref={group} {...props} dispose={null} position={position}>
        {/*<mesh
        geometry={nodes.Fondo_NQR.geometry}
        visible={fondoHideNQR}
        position={[-0.9, 0, 0]}
      >
        <meshStandardMaterial
          color={0xffffff}
          map={ambiente}
          envMapIntensity={1.2}
          aoMap={daoNQR}
          metalness={1}
        />
      </mesh>
      <mesh geometry={nodes.FondoQLR_NQR.geometry} visible={fondoHideQLR}>
        <meshStandardMaterial
          color={0xffffff}
          map={ambiente}
          envMapIntensity={1.2}
          aoMap={daoQLR}
          metalness={1}
        />
      </mesh>*/}
        <mesh
          geometry={nodes.GomaNegra_NQR.geometry}
          material={GomaNegra}
        ></mesh>
        {/*<mesh
        geometry={nodes.Sphere_NQR.geometry}
        material={Pano}
        visible={esferaHide}
      ></mesh>*/}
        <mesh
          geometry={nodes.GomaNegra2_NQR.geometry}
          material={GomaNegra}
        ></mesh>
        <mesh
          geometry={nodes.GomaNegra_NQR.geometry}
          material={GomaNegra}
        ></mesh>

        <mesh
          geometry={nodes.PlasticoAmarillo_NQR.geometry}
          material={PlasticoAmarillo}
          position={[-2.05, 0, 0]}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoAmarillo2_NQR.geometry}
          material={PlasticoAmarilloM}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoBlanco_NQR.geometry}
          material={PlasticoBlanco}
          position={[-2.05, 0, 0]}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoBlanco2_NQR.geometry}
          material={PlasticoBlancoM}
        ></mesh>
        {/*<mesh
        geometry={nodes.PlasticoBlancoFaro_NQR.geometry}
        material={PlasticoBlancoFaro}
      ></mesh>
      <mesh
        geometry={nodes.PlasticoBlancoR_NQR.geometry}
        material={PlasticoBlancoR}
      ></mesh>*/}
        <mesh
          geometry={nodes.PlasticoMicasFrontal_NQR.geometry}
          material={PlasticoMicasFrontalRelieve}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoMicasFrontalRelieve_NQR.geometry}
          material={PlasticoMicasFrontal}
        ></mesh>

        <mesh
          geometry={nodes.PlasticoRojo_NQR.geometry}
          material={PlasticoRojo}
          position={[-2.05, 0, 0]}
        ></mesh>
        {/*
      <mesh
        geometry={nodes.PlasticoRojoFaro_NQR.geometry}
        material={PlasticoRojoFaro}
      ></mesh>*/}
        <mesh geometry={nodes.Espejo_NQR.geometry} material={Espejo}></mesh>
        <mesh
          geometry={nodes.EspejoMove_NQR.geometry}
          material={Espejo}
          position={[-2.05, 0, 0]}
        ></mesh>

        {/*<mesh geometry={nodes.LogoGris_NQR.geometry} material={LogoGris}></mesh>*/}
        <Select name="puertaR" enabled={hovered}>
          <animated.mesh
            position={[2.156, 0.708, 0.882]}
            rotation={rotation}
            onClick={() => setActive(!active)}
            ref={myMesh}
            scale={[1, 1, -1]}
            onPointerOver={() => {
              set(true);
            }}
            onPointerOut={() => {
              set(false);
            }}
          >
            <mesh
              geometry={nodes.EspejoR_NQR.geometry}
              material={Espejo}
            ></mesh>
            <mesh
              geometry={nodes.PinturaBlancaMetalR_NQR.geometry}
              material={PinturaBlanca1}
            ></mesh>
            <mesh
              geometry={nodes.PinturaNegraMetalR_NQR.geometry}
              material={PinturaNegraMetal}
              scale={[1, 1, -1]}
            ></mesh>
            <mesh
              geometry={nodes.PlasticoNegroR_NQR.geometry}
              material={PlasticoNegro}
            ></mesh>
            <mesh
              geometry={nodes.VidriosOscurosR_NQR.geometry}
              material={VidriosOscuros}
            ></mesh>

            <mesh
              geometry={nodes.ChromadoR_NQR.geometry}
              material={Chromado}
            ></mesh>
            <mesh
              geometry={nodes.GomaNegraR_NQR.geometry}
              material={GomaNegra}
            ></mesh>
            <mesh
              geometry={nodes.GomaNegra2R_NQR.geometry}
              material={GomaNegra}
            ></mesh>
            <mesh
              geometry={nodes.PlasticoGrisR_NQR.geometry}
              material={PlasticoGris}
            ></mesh>
            <mesh
              geometry={nodes.PlasticoNegroR_NQR.geometry}
              material={PlasticoNegro}
            ></mesh>
            <mesh
              geometry={nodes.PlasticoMicasFrontalR_NQR.geometry}
              material={PlasticoMicasFrontalRelieve}
            ></mesh>
          </animated.mesh>
        </Select>

        <Select name="puertaL" enabled={hoveredL}>
          <animated.mesh
            position={[2.156, 0.708, -0.882]}
            rotation={rotationL}
            onClick={() => setActiveL(!activeL)}
            ref={myMeshL}
            onPointerOver={() => {
              setL(true);
            }}
            onPointerOut={() => {
              setL(false);
            }}
          >
            <mesh
              geometry={nodes.PinturaBlancaMetalL_NQR.geometry}
              material={PinturaBlanca1}
            ></mesh>
            <mesh
              geometry={nodes.PinturaNegraMetalL_NQR.geometry}
              material={PinturaNegraMetal}
            ></mesh>
            <mesh
              geometry={nodes.EspejoL_NQR.geometry}
              material={Espejo}
            ></mesh>

            <mesh
              geometry={nodes.PlasticoNegroL_NQR.geometry}
              material={PlasticoNegro}
            ></mesh>
            <mesh
              geometry={nodes.VidriosOscurosL_NQR.geometry}
              material={VidriosOscuros}
            ></mesh>

            <mesh
              geometry={nodes.ChromadoLNQR.geometry}
              material={Chromado}
            ></mesh>
            <mesh
              geometry={nodes.GomaNegraL_NQR.geometry}
              material={GomaNegra}
            ></mesh>
            <mesh
              geometry={nodes.GomaNegra2L_NQR.geometry}
              material={GomaNegra}
            ></mesh>
            <mesh
              geometry={nodes.PlasticoGrisL_NQR.geometry}
              material={PlasticoGris}
            ></mesh>
            <mesh
              geometry={nodes.PlasticoNegroL_NQR.geometry}
              material={PlasticoNegro}
            ></mesh>
            <mesh
              geometry={nodes.PlasticoMicasFrontalL_NQR.geometry}
              material={PlasticoMicasFrontalRelieve}
            ></mesh>
          </animated.mesh>
        </Select>

        <mesh
          geometry={nodes.VidriosOscuros_NQR.geometry}
          material={VidriosOscuros}
        ></mesh>
        <mesh
          geometry={nodes.VidriosOscuros_NQR.geometry}
          material={VidriosOscuros}
        ></mesh>

        <mesh geometry={nodes.Cauchos1_NQR.geometry} material={Cauchos}></mesh>
        <mesh
          geometry={nodes.CauchosMove_NQR.geometry}
          material={Cauchos}
          position={[-1.416, 0, 0]}
        ></mesh>
        <mesh
          geometry={nodes.Cauchos3_NQR.geometry}
          material={Cauchos}
          position={[-1.416, 0, 0]}
        ></mesh>
        <mesh
          geometry={nodes.CauchosFalseMove_NQR.geometry}
          material={Cauchos}
          position={[-1.416, 0, 0]}
        ></mesh>
        <mesh geometry={nodes.Chromado_NQR.geometry} material={Chromado}></mesh>
        <mesh geometry={nodes.Chromado_NQR.geometry} material={Chromado}></mesh>
        <mesh
          geometry={nodes.ChromadoMove1_NQR.geometry}
          material={Chromado}
          position={[-1.416, 0, 0]}
        ></mesh>
        <mesh
          geometry={nodes.ChromadoMove2_NQR.geometry}
          material={Chromado}
          position={[-2.05, 0, 0]}
        ></mesh>
        <mesh
          geometry={nodes.LogoChromado_NQR.geometry}
          material={LogoChromado}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoNegro1_NQR.geometry}
          material={PlasticoNegro}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoNegro2_NQR.geometry}
          material={PlasticoNegro}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoNegro3_NQR.geometry}
          material={PlasticoNegro}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoNegroMove_NQR.geometry}
          material={PlasticoNegro}
          position={[-2.05, 0, 0]}
        ></mesh>

        <mesh
          geometry={nodes.PinturaBlancaMetal1_NQR.geometry}
          material={PinturaBlanca1}
        ></mesh>

        <mesh
          geometry={nodes.PinturaBlancaMetal2_NQR.geometry}
          material={PinturaBlanca1}
          position={[-2.05, 0, 0]}
        ></mesh>
        <mesh
          geometry={nodes.PinturaBlancaMetal3_NQR.geometry}
          material={PinturaBlanca1}
        ></mesh>
        <mesh
          geometry={nodes.PinturaBlancaMetal4_NQR.geometry}
          material={PinturaBlanca2}
          position={[-1.416, 0, 0]}
        ></mesh>
        <mesh
          geometry={nodes.PinturaBlancaMetal5_NQR.geometry}
          material={PinturaGisMetal}
        ></mesh>
        <mesh
          geometry={nodes.PinturaBlancaMetalfalse_NQR.geometry}
          material={PinturaGisMetal}
          position={[-1.416, 0, 0]}
        ></mesh>
        <mesh
          geometry={nodes.PinturaBlancaMetal_NQR1.geometry}
          material={PinturaBlanca1}
        ></mesh>
        <mesh
          geometry={nodes.PinturaBlancaMetal_NQR2.geometry}
          material={PinturaBlanca1}
        ></mesh>
        <mesh
          geometry={nodes.PinturaBlancaMetal_NQR3.geometry}
          material={PinturaBlanca1}
        ></mesh>

        <mesh
          geometry={nodes.PinturaBlancaMetalMove_NQR.geometry}
          material={PinturaGisMetal}
          position={[-1.416, 0, 0]}
        ></mesh>

        <mesh
          geometry={nodes.PinturaNegraMetal_NQR.geometry}
          material={PinturaNegraMetal}
        ></mesh>
        <mesh
          geometry={nodes.PinturaNegraMetal2_NQR.geometry}
          material={PinturaNegraMetal2}
        ></mesh>
        <mesh
          geometry={nodes.PinturaNegraMetal_NQR1.geometry}
          material={PinturaNegraMetal}
        ></mesh>
        <mesh
          geometry={nodes.PinturaNegraMetal_NQR2.geometry}
          material={PinturaNegraMetal}
        ></mesh>
        <mesh
          geometry={nodes.PinturaNegraMetalMove_NQR.geometry}
          material={PinturaNegraMetal}
          position={[-2.05, 0, 0]}
        ></mesh>
        <mesh
          geometry={nodes.PinturaNegraMetalMove2_NQR.geometry}
          material={PinturaNegraMetal}
          position={[-1.416, 0, 0]}
        ></mesh>

        <mesh geometry={nodes.Escape_NQR.geometry} material={Escape}></mesh>
        <mesh
          geometry={nodes.EscapeFalse_NQR.geometry}
          material={Escape}
        ></mesh>
        <mesh geometry={nodes.Baterias_NQR.geometry} material={Baterias}></mesh>

        <mesh
          geometry={nodes.PlasticoGris_NQR.geometry}
          material={PlasticoGris}
        ></mesh>
        <mesh
          geometry={nodes.Alfombra_NQR.geometry}
          material={AlfombraGris}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoAmarilloM_NQR.geometry}
          material={PlasticoAmarilloM}
        ></mesh>
        <mesh
          geometry={nodes.PlasticoGrisOscuro_NQR.geometry}
          material={PlasticoGrisOscuro}
        ></mesh>
        <mesh
          geometry={nodes.PlastinoNegroBrillante_NQR.geometry}
          material={PlasticoInteriorBrillante}
        ></mesh>
        <mesh geometry={nodes.TelaAzul_NQR.geometry} material={TelaAzul}></mesh>
        <mesh geometry={nodes.TelaGris_NQR.geometry} material={TelaGris}></mesh>
        <mesh
          geometry={nodes.TelaGris2_NQR.geometry}
          material={TelaGris2}
        ></mesh>
        <mesh
          geometry={nodes.TacometroAire_NQR.geometry}
          material={PlasticoInteriorBrillante2}
        ></mesh>
        <mesh
          geometry={nodes.PlastinoRojoBotton_NQR.geometry}
          material={PlasticoRojoTacometro}
        ></mesh>
        <Placas position={[2.385, 0.5, 0]} camion={"NQR"} />
      </group>
      <CinturonNQRQLR position={position} />
    </>
  );
}

useGLTF.preload("./Models/NQR.gltf");

/*

*/
