import React from "react";

import "./App.css";
import Traviz from "./components/Traviz";
import Loading from "./components/Loading";
import NQR from "./components/NQR";
import NQRQLR from "./components/NQRQLR";
import NPR from "./components/NPR";
import CinturonTraviz from "./components/CinturonTraviz";
import { useContext } from "react";
import { AppContext } from "./context/AppContext";
import Slider from "./components/Slider";
import Images from "./carousel/images";
import { Route, Routes } from "react-router-dom";
import Interfaz from "./components/Interfaz";
import Anotaciones from "./components/Anotaciones";
import AnotacionesQLR from "./components/AnotacionesQLR";
import AnotacionesNQR from "./components/AnotacionesNQR";
import AnotacionesQMR from "./components/AnotacionesQMR";
import AnotacionesNMR from "./components/AnotacionesNMR";
import AnotacionesNPR from "./components/AnotacionesNPR";
import AnotacionesFVR from "./components/AnotacionesFVR";
import NMR from "./components/NMR.js";
import FVR from "./components/FVR.js";

function App() {
  // Guardamos dentro de "global" el contexto
  const global = useContext(AppContext);

  return (
    <div className="Container" style={{ width: "100%", height: "100vh" }}>
      <Routes>
        <Route
          path="/"
          element={
            <>
              {global.showIdioma && <Loading />}
              <Slider images={Images} />
            </>
          }
        />

        <Route
          path="/Traviz-flat-Bed"
          element={
            <Interfaz
              camionZoom={2.2}
              camionDistanciaExterior={8}
              camionPosicionInterior={{ x: 1.2, y: 0.65 }}
              camionAltura={0.2}
              camionActive={"Traviz Flat Bed"}
              serieActive={"./Media/p-series.svg"}
              camion={
                <>
                  <Traviz camionVisible={true} fondo={global.activeFondo} />
                  <CinturonTraviz />
                </>
              }
              anotaciones={
                <Anotaciones
                  activeIdioma={global.activeIdioma}
                  activeMensajes={global.activeMensajes}
                />
              }
            />
          }
        />
        <Route
          path="/Traviz"
          element={
            <Interfaz
              camionZoom={2.2}
              camionDistanciaExterior={8}
              camionPosicionInterior={{ x: 1.2, y: 0.65 }}
              camionAltura={0.2}
              camionActive={"Traviz"}
              serieActive={"./Media/p-series.svg"}
              camion={
                <>
                  <Traviz camionVisible={false} fondo={global.activeFondo} />
                  <CinturonTraviz />
                </>
              }
              anotaciones={
                <Anotaciones
                  activeIdioma={global.activeIdioma}
                  activeMensajes={global.activeMensajes}
                />
              }
            />
          }
        />
        <Route
          path="/QLR77L"
          element={
            <Interfaz
              camionZoom={2.2}
              camionDistanciaExterior={8}
              camionPosicionInterior={{ x: 1.25, y: 0.75 }}
              camionAltura={0.25}
              camionActive={"QLR 77L"}
              serieActive={"./Media/q-series.svg"}
              camion={
                <>
                  <NQRQLR
                    QLR={true}
                    NQR={false}
                    dobleCaucho={false}
                    fondo={global.activeFondo}
                    vista={global.activeVista}
                  />
                </>
              }
              anotaciones={
                <AnotacionesQLR
                  activeIdioma={global.activeIdioma}
                  activeMensajes={global.activeMensajes}
                />
              }
            />
          }
        />
        <Route
          path="/QMR77L"
          element={
            <Interfaz
              camionZoom={2.2}
              camionDistanciaExterior={8}
              camionPosicionInterior={{ x: 1.25, y: 0.75 }}
              camionAltura={0.25}
              camionActive={"QMR 77L"}
              serieActive={"./Media/q-series.svg"}
              camion={
                <>
                  <NQRQLR
                    QLR={true}
                    NQR={false}
                    dobleCaucho={true}
                    fondo={global.activeFondo}
                    vista={global.activeVista}
                  />
                </>
              }
              anotaciones={
                <AnotacionesQMR
                  activeIdioma={global.activeIdioma}
                  activeMensajes={global.activeMensajes}
                />
              }
            />
          }
        />
        <Route
          on
          path="/NQR90L"
          render={() => {}}
          element={
            <Interfaz
              camionZoom={3.1}
              camionDistanciaExterior={13}
              camionPosicionInterior={{ x: 1.25, y: 0.75 }}
              camionAltura={0.3}
              camionActive={"NQR 90L"}
              serieActive={"./Media/n-series.svg"}
              camion={
                <>
                  <NQR fondo={global.activeFondo} vista={global.activeVista} />
                </>
              }
              anotaciones={
                <AnotacionesNQR
                  activeIdioma={global.activeIdioma}
                  activeMensajes={global.activeMensajes}
                  vista={global.activeVista}
                />
              }
            />
          }
        />
        <Route
          on
          path="/NPR"
          render={() => {}}
          element={
            <Interfaz
              camionZoom={3.1}
              camionDistanciaExterior={13}
              camionPosicionInterior={{ x: 1.25, y: 0.75 }}
              camionAltura={0.3}
              camionActive={"NPR"}
              serieActive={"./Media/n-series.svg"}
              camion={
                <>
                  <NPR fondo={global.activeFondo} vista={global.activeVista} />
                </>
              }
              anotaciones={
                <AnotacionesNPR
                  activeIdioma={global.activeIdioma}
                  activeMensajes={global.activeMensajes}
                  vista={global.activeVista}
                />
              }
            />
          }
        />
        <Route
          on
          path="/NMR"
          render={() => {}}
          element={
            <Interfaz
              camionZoom={1.9}
              camionDistanciaExterior={8}
              camionPosicionInterior={{ x: 1.25, y: 0.75 }}
              camionAltura={0.65}
              camionActive={"NMR"}
              serieActive={"./Media/n-series.svg"}
              camion={
                <>
                  <NMR fondo={global.activeFondo} vista={global.activeVista} />
                </>
              }
              anotaciones={
                <AnotacionesNMR
                  activeIdioma={global.activeIdioma}
                  activeMensajes={global.activeMensajes}
                  vista={global.activeVista}
                />
              }
            />
          }
        />
        <Route
          on
          path="/FVR"
          render={() => {}}
          element={
            <Interfaz
              camionZoom={2.6}
              camionDistanciaExterior={13}
              camionPosicionInterior={{ x: 1.25, y: 0.65 }}
              camionAltura={0.6}
              camionActive={"FVR"}
              serieActive={"./Media/n-series.svg"}
              camion={
                <>
                  <FVR fondo={global.activeFondo} vista={global.activeVista} />
                </>
              }
              anotaciones={
                <AnotacionesFVR
                  activeIdioma={global.activeIdioma}
                  activeMensajes={global.activeMensajes}
                  vista={global.activeVista}
                />
              }
            />
          }
        />
      </Routes>
    </div>
  );
}

export default App;
